import Fetcher from '../../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../../constants'
import {
  PaymentStatusList,
  PaymentStatusParams,
  PaymentStatusRequest
} from './types'

const fetcher = new Fetcher({})

export const getPaymentStatusData: PaymentStatusRequest = async (data) =>
  fetcher.request<PaymentStatusParams, PaymentStatusList>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.FINANCES_DASHBOARD}${API_DICTIONARY.PAYMENT_BY_STATUS}`,
    method: HTTP_METHODS.GET,
    ...data
  })
