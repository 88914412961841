import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'antd'
import cn from 'classnames'
import {
  Typography,
  TYPOGRAPHY_ALIGNMENT,
  TYPOGRAPHY_WEIGHT
} from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { Popup } from 'components/Popup'
import { Forms } from 'components/Forms'
import { DepartmentsManagement } from 'features/DepartmentsManagement/DepartmentsManagement'
import { HSUsersListByRole } from './HSUsersListByRole'
import { IconFullName } from './IconFullName'
import { HospitalsTable } from './HospitalsTable/HospitalsTable'
import { UsersTable } from './UsersTable/UsersTable'
import { fetchDepartments } from 'pages/CRUDUser/api'
import { fetchHealthSystem } from 'features/HealthSystems/api'
import { getUser } from 'redux/store/user/getters'
import { setLoading } from 'redux/store/common/slice'
import { formatAddress, safeString, fullFormatPhoneNumber } from 'helper/common'
import { ACTIONS, SUBJECTS, Can, ability } from 'features/Permission'
import { BTN_TXT } from 'constants/txt'
import { ROLES } from 'features/Permission/constants'
import {
  HEALTH_SYSTEM_TABLES_TABS,
  initialHealthSystemDetails
} from './constants'
import { ReactComponent as Edit16 } from 'assets/svg/Edit16.svg'
import { ReactComponent as Hospital } from 'assets/svg/Hospital.svg'
import { THealthSystemProps, THealthSystemDetails } from './types'
import './styles.scss'
import usePopup from 'hooks/usePopup'
import BankAccountWrapper from '../BankAccount'
import {
  TResponseDetailsAccount,
  TResponseLinkAccount
} from '../BankAccount/types'
import { getBankDetails, getLikToAccount } from '../Financials/api'
import { HospitalAdminHealthSystemDetails } from './HospitalAdminHealthSystemDetails'
import { StakeholderHealthSystemDetails } from './StakeholderHealthSystemDetails'
import DownloadQRCode from './DownloadQRCode'

export const HealthSystemDetails = ({
  hsId,
  isCommunity,
  getHealthSystems
}: THealthSystemProps) => {
  const [data, setData] = useState<THealthSystemDetails>(
    initialHealthSystemDetails
  )
  const [initData, setInitData] = useState(data)
  const [visePresident, setVisePresident] = useState({} as any)
  const [contractStewards, setContractStewards] = useState([] as any)
  const [legals, setLegals] = useState([] as any)
  const [analysts, setAnalysts] = useState([] as any)
  const [rcas, setRcas] = useState([] as any)
  const [departments, setDepartments] = useState([] as any)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [activeTab, setActiveTab] = useState(HEALTH_SYSTEM_TABLES_TABS[0].key)
  const [openEditPopup, setOpenEditPopup] = useState(false)
  const [openArchivePopup, setOpenArchivePopup] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const currentHs = user.health_system || hsId

  const [linkAccount, setLinkAccount] = useState<TResponseLinkAccount>()
  const [dataAccount, setDataAccount] = useState<TResponseDetailsAccount>()

  const discardEditHealthSystemPopup = usePopup()
  const isHealthSystemChanged = useCallback(
    (newData: any) => {
      const newUsers: any = []
      if (!!newData?.analyst) newUsers.push(...newData.analyst)
      if (!!newData?.contract_steward)
        newUsers.push(...newData.contract_steward)
      if (!!newData?.legal) newUsers.push(...newData.legal)

      if (newData.vice_president) {
        newUsers.push({
          uuid: newData.vice_president?.value ?? newData.vice_president
        })
      }

      const isAddressChanged =
        safeString(initData.address?.city) !== safeString(newData?.city) ||
        safeString(initData.address?.state) !== safeString(newData?.state) ||
        safeString(initData.address?.street) !== safeString(newData?.street) ||
        safeString(initData.address?.zip_code) !== safeString(newData?.zip_code)

      const isContactPersonChanged =
        safeString(initData.contact_person?.email) !==
          safeString(newData?.person_email) ||
        safeString(initData.contact_person?.first_name) !==
          safeString(newData?.person_first_name) ||
        safeString(initData.contact_person?.last_name) !==
          safeString(newData?.person_last_name) ||
        safeString(initData.contact_person?.phone_number) !==
          safeString(newData?.person_phone_number) ||
        safeString(initData.contact_person?.position) !==
          safeString(newData?.person_position)

      const areUsersChanged =
        initData.users?.length !== newUsers?.length ||
        initData.users?.some(
          (findUser) =>
            newUsers.findIndex(
              (user) => findUser.uuid === (user?.uuid ?? user)
            ) === -1
        )

      const isRestInfoChanged =
        safeString(initData?.email) !== safeString(newData?.email) ||
        safeString(initData?.name) !== safeString(newData?.name) ||
        safeString(initData?.phone_number) !==
          safeString(newData?.phone_number) ||
        safeString(initData?.tax_id) !== safeString(newData?.tax_id)

      return (
        isAddressChanged ||
        isContactPersonChanged ||
        areUsersChanged ||
        isRestInfoChanged
      )
    },
    [initData]
  )

  const getHealthSystemDetails = useCallback(async () => {
    dispatch(setLoading(true))
    try {
      await fetchHealthSystem(
        user.community as string,
        currentHs as string
      ).then((resp) => {
        setData(resp.data)
        setInitData(resp.data)
      })
      await fetchDepartments(
        user.community as string,
        currentHs as string
      ).then((resp) => {
        if (!resp.data?.results) {
          return
        }

        setDepartments(resp.data.results)
      })
    } catch (e) {
      dispatch(setLoading(false))
    } finally {
      setFirstLoad(false)
    }
  }, [currentHs, dispatch, user.community])

  const onChangeCollapse = () => setIsCollapsed(!isCollapsed)

  const discardEditHealthSystem = () => {
    setOpenEditPopup(false)
    setData(initData)
    form.resetFields()
    dispatch(setLoading(false))
  }

  const handleSubmitDiscardEditHealthSystemPopup = () => {
    discardEditHealthSystem()
    discardEditHealthSystemPopup.actions.close()
  }

  const handleCloseEditPopup = () => {
    if (isHealthSystemChanged(form.getFieldsValue())) {
      discardEditHealthSystemPopup.actions.open()

      return
    }

    discardEditHealthSystem()
  }

  const updateDepartmentsList = () => {
    fetchDepartments(data?.community?.uuid, data?.uuid).then((data) =>
      setDepartments(data?.data?.results)
    )
  }

  useEffect(() => {
    if (user.community && currentHs) {
      getHealthSystemDetails()
    }
  }, [currentHs, getHealthSystemDetails, user.community])

  useEffect(() => {
    if (data?.uuid) {
      setVisePresident(
        data?.users
          ? data?.users?.find((user) => user.role === ROLES.VICE_PRESIDENT)
          : []
      )
      setContractStewards(
        data?.users
          ? data?.users?.filter((user) => {
              if (user.role === ROLES.CONTRACT_STEWARD) {
                return user
              }
            })
          : []
      )
      setLegals(
        data?.users
          ? data?.users?.filter((user) => {
              if (user.role === ROLES.LEGAL) {
                return user
              }
            })
          : []
      )
      setAnalysts(
        data?.users
          ? data?.users?.filter((user) => {
              if (user.role === ROLES.ANALYST) {
                return user
              }
            })
          : []
      )
      setRcas(
        data?.users
          ? data?.users?.filter((user) => {
              if (user.role === ROLES.COMMUNITY_RCA) {
                return user
              }
            })
          : []
      )
    }
  }, [data?.users, data?.uuid])

  useEffect(() => {
    if (data.uuid && ability.can(ACTIONS.ADD, SUBJECTS.BANK_ACCOUNT)) {
      getLikToAccount({
        facility_id: data.uuid,
        facility_type: 'health_system'
      }).then((res) => {
        setLinkAccount(res.data)
      })
      getBankDetails({
        facility_id: data.uuid,
        facility_type: 'health_system'
      }).then((res) => {
        setDataAccount(res.data)
      })
    }
  }, [data.uuid])
  return (
    <div
      className={cn('hs-details', {
        'hs-details--no-padding': !isCommunity
      })}
    >
      {firstLoad && user.health_system ? null : !isCommunity && !currentHs ? (
        <div className="hs-details--empty">
          <Hospital />
          <Typography.Body1
            weight={TYPOGRAPHY_WEIGHT.BOLD}
            align={TYPOGRAPHY_ALIGNMENT.CENTER}
          >
            Health system is not assigned.
            <br />
            Please contact Community President in your profile
          </Typography.Body1>
        </div>
      ) : user.role === ROLES.HOSPITAL_ADMIN ||
        user.role === ROLES.INFLUENCER ? (
        <HospitalAdminHealthSystemDetails />
      ) : user.role === ROLES.STAKEHOLDER ? (
        <StakeholderHealthSystemDetails />
      ) : (
        <>
          <div className="hs-details__content">
            <div className="hs-details__title">
              {!isCommunity && (
                <Typography.Headline4>{data.name}</Typography.Headline4>
              )}
              <div className="row align-center gap-8">
                <Can I={ACTIONS.DOWNLOAD} a={SUBJECTS.QR_CODE}>
                  <DownloadQRCode uuid={data.uuid} />
                </Can>
                <Can I={ACTIONS.CRUD} a={SUBJECTS.HEALTH_SYSTEM}>
                  <Button.Icon
                    type={BUTTON_TYPES.DEFAULT}
                    onClick={() => setOpenEditPopup(true)}
                    className="hs-details__edit-btn"
                    icon={<Edit16 />}
                  />
                </Can>
              </div>
            </div>
            <Typography.Headline6 weight="semi-bold">
              General
            </Typography.Headline6>
            <div key="General" className="hs-details_general-info">
              <FieldWithLabel title="Tax Id">{data?.tax_id}</FieldWithLabel>
              <FieldWithLabel title="Email">{data?.email}</FieldWithLabel>
              <FieldWithLabel title="Phone number">
                {fullFormatPhoneNumber(data?.phone_number)}
              </FieldWithLabel>
              <FieldWithLabel title="Address">
                {formatAddress(data?.address)}
              </FieldWithLabel>
            </div>
            <Can I={ACTIONS.CRUD} a={SUBJECTS.HEALTH_SYSTEM}>
              {linkAccount && (
                <div className="row mt-32">
                  <BankAccountWrapper
                    linkAccount={linkAccount}
                    dataAccount={dataAccount}
                    type="community"
                    uuid={user.community}
                    className="health-system-account"
                  />
                </div>
              )}
            </Can>
            {isCollapsed && (
              <>
                {data?.contact_person && (
                  <>
                    <Typography.Headline6
                      className="hs-details_top-margin"
                      weight="semi-bold"
                    >
                      Contact person
                    </Typography.Headline6>
                    <div
                      key="Contact person"
                      className="hs-details_contact-info"
                    >
                      <FieldWithLabel title="Name">
                        {data?.contact_person?.first_name} &nbsp;
                        {data?.contact_person?.last_name}
                      </FieldWithLabel>
                      <FieldWithLabel title="Email">
                        {data?.contact_person?.email}
                      </FieldWithLabel>
                      <FieldWithLabel title="Position">
                        {data?.contact_person?.position}
                      </FieldWithLabel>
                      <FieldWithLabel title="Phone number">
                        {fullFormatPhoneNumber(
                          data?.contact_person?.phone_number
                        )}
                      </FieldWithLabel>
                    </div>
                  </>
                )}
                <div className="hs-details_vs-president">
                  <Typography.Headline6
                    className="hs-details_top-margin"
                    weight="semi-bold"
                  >
                    HS users
                  </Typography.Headline6>
                  <div className="hs-details_title-text_container">
                    <Typography.Label className="hs-details_title-text_container_label">
                      Vice president
                    </Typography.Label>
                    <IconFullName
                      firstName={visePresident?.first_name}
                      lastName={visePresident?.last_name}
                      src={
                        visePresident?.small_image_url ||
                        visePresident?.avatar?.file_preview
                      }
                      uuid={visePresident?.uuid}
                    />
                  </div>
                </div>
                <div className="hs-details_users-list_container">
                  {!!contractStewards.length && (
                    <HSUsersListByRole
                      users={contractStewards}
                      roleName="Contract Steward"
                    />
                  )}
                  {!!legals.length && (
                    <HSUsersListByRole users={legals} roleName="Legal" />
                  )}
                  {!!analysts.length && (
                    <HSUsersListByRole users={analysts} roleName="Analyst" />
                  )}
                  {!!rcas.length && (
                    <HSUsersListByRole
                      users={rcas}
                      roleName="Revenue cycle advisor"
                    />
                  )}
                </div>
                {!!departments?.length && (
                  <div className="hs-details_departments_container">
                    <div className="hs-details_departments_title-management hs-details_top-margin">
                      <Typography.Headline6 weight="semi-bold">
                        Departments
                      </Typography.Headline6>
                      <DepartmentsManagement
                        initStateDepartments={departments.filter(
                          (department) => !department.is_default
                        )}
                        communityUUID={data?.community?.uuid}
                        HSUUID={data?.uuid}
                        onClose={updateDepartmentsList}
                        HSName={data.name}
                      />
                    </div>
                    <div className="hs-details_departments">
                      {departments
                        ?.map((department) => department.name)
                        .join(', ')}
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="hs-details_button-hide_container">
              <Button
                type={BUTTON_TYPES.LINK}
                className="hs-details_button-hide"
                onClick={onChangeCollapse}
              >
                {`${isCollapsed ? 'Hide' : 'Show more'} information`}
              </Button>
            </div>
          </div>
          {activeTab === HEALTH_SYSTEM_TABLES_TABS[0].key && (
            <HospitalsTable
              hsId={data.uuid}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          )}
          {activeTab === HEALTH_SYSTEM_TABLES_TABS[1].key && (
            <UsersTable
              hsId={data.uuid}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          )}
          <Popup
            visible={openEditPopup}
            onCancel={handleCloseEditPopup}
            title="Edit Health System"
            width={910}
            footer={
              <>
                <div className="popup-buttons">
                  <Button
                    onClick={handleCloseEditPopup}
                    type={BUTTON_TYPES.DEFAULT}
                    upperCase
                  >
                    {BTN_TXT.CANCEL}
                  </Button>
                  <Button
                    type={BUTTON_TYPES.PRIMARY}
                    htmlType="submit"
                    form="add-edit-health-system-form"
                    upperCase
                  >
                    {BTN_TXT.SAVE}
                  </Button>
                </div>
              </>
            }
          >
            <Forms.AddEditHealthSystem
              data={data}
              form={form}
              callback={() => {
                getHealthSystemDetails()
                !!getHealthSystems && getHealthSystems()
                discardEditHealthSystem()
              }}
            />
          </Popup>
          <Popup
            visible={openArchivePopup}
            onCancel={() => setOpenArchivePopup(false)}
            title={`Archive ${data.name}?`}
            wrapClassName="popup-over"
            width={442}
            footer={
              <>
                <Button
                  onClick={() => setOpenArchivePopup(false)}
                  type={BUTTON_TYPES.DEFAULT}
                  upperCase
                >
                  {BTN_TXT.CANCEL}
                </Button>
              </>
            }
          >
            <Typography.Body1>
              Hospitals can be active and users might have contracts. Do you
              really want to archive Health System?
            </Typography.Body1>
          </Popup>
          <Popup.Discard
            visible={discardEditHealthSystemPopup.state.visible}
            onCancel={discardEditHealthSystemPopup.actions.close}
            onSubmit={handleSubmitDiscardEditHealthSystemPopup}
          >
            <Typography.Body1>
              {`You've made changes to ${initData.name}.`}
            </Typography.Body1>
            <Typography.Body1>
              Do you want to exit without saving data?
            </Typography.Body1>
          </Popup.Discard>
        </>
      )}
    </div>
  )
}
