import Search from 'features/ContractDetails/HSList/HSTable/Search/Search'
import { Table } from 'components/Table'
import { isTableHasParams } from 'helper/common'
import { IContractHealthSystem } from 'features/ContractDetails/HSList/types'
import { Props } from './types'
import './styles.scss'

export const HSTable = ({
  columns,
  tableData,
  searchValue,
  firstLoad,
  totalNumber,
  pageInfo,
  debouncedSearch,
  handleTableChange,
  handleChangePageSize,
  setSearchValue,
  showMark
}: Props) => (
  <div className="table-wrapper hs-list">
    <div className="table-wrapper__header">
      <Search value={searchValue} onChange={setSearchValue} />
    </div>
    {!firstLoad && (
      <Table<IContractHealthSystem>
        scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
        dataSource={tableData}
        rowClassName={(record) =>
          showMark && record.not_completed ? 'warning-highlight' : ''
        }
        className="contacts-hs-list-table"
        columns={columns(showMark)}
        onChange={handleTableChange}
        hasSearchOrFilters={isTableHasParams(debouncedSearch, false)}
        onChangePage={handleChangePageSize}
        pageSize={pageInfo.pageSize}
        pagination={{
          pageSize: pageInfo.pageSize,
          current: pageInfo.pageNumber,
          total: totalNumber
        }}
      />
    )}
  </div>
)
