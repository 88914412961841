import {
  createContext,
  Dispatch,
  FC,
  Key,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { TOption } from '../../../components/Select/types'
import {
  IChangedRows,
  IUncategorizedRequestPayload,
  TUncategorizedInvoiceWithKey
} from '../PendingClassification/types'
import { fetchContractCategories } from '../../../pages/CRUDUser/api'
import { formatOptionNameValue } from '../../../helper/optionsFormatters'
import { classifyUncategorizedInvoiceData } from '../../../pages/InvoiceData/api'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../redux/store/common/slice'

type ContextProps = {
  state: {
    selectedRows: Key[]
    isEditHidden: boolean
    contractCategories: TOption[]
    changedRows: IChangedRows
    hasPendingChanges: boolean
    saveHidden: boolean
    reloadIndicator: boolean
    tableData: TUncategorizedInvoiceWithKey[]
  }
  actions: {
    setSelectedRows: Dispatch<SetStateAction<Key[]>>
    setChangedRows: Dispatch<SetStateAction<IChangedRows>>
    saveChanges: () => void
    setTableData: Dispatch<SetStateAction<TUncategorizedInvoiceWithKey[]>>
  }
}

const PendingClassificationEditContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const PendingClassificationEditContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const dispatch = useDispatch()
  const [selectedRows, setSelectedRows] = useState<Key[]>([])
  const [contractCategories, setContractCategories] = useState<TOption[]>([])
  const [changedRows, setChangedRows] = useState<IChangedRows>({})
  const [reloadIndicator, setReloadIndicator] = useState<boolean>(false)
  const [tableData, setTableData] = useState<TUncategorizedInvoiceWithKey[]>([])

  const getCategoriesList = useCallback(() => {
    fetchContractCategories().then((resp) => {
      if (!resp.data?.results) {
        return
      }

      setContractCategories(resp.data.results.map(formatOptionNameValue))
    })
  }, [])

  const hasPendingChanges = useMemo(
    () => !!Object.keys(changedRows).length,
    [changedRows]
  )

  const saveHidden = useMemo(
    () =>
      Object.keys(changedRows).every((key) => {
        return Object.keys(changedRows[key]).every(
          (subKey) => changedRows[key][subKey] === undefined
        )
      }),
    [changedRows]
  )

  const isEditHidden = useMemo(
    () => !selectedRows.length,
    [selectedRows.length]
  )

  const saveChanges = useCallback(() => {
    const requestData = Object.keys(changedRows).reduce((acc, key) => {
      const prevValue = tableData.find((item) => item.key === key)
      const line: IUncategorizedRequestPayload = {
        item: key.toString(),
        contract_category:
          changedRows[key].contract_category ||
          prevValue?.contract_category ||
          null,
        unspsc_code:
          changedRows[key].unspsc_code || prevValue?.unspsc_code || null
      }
      return [...acc, line]
    }, [] as IUncategorizedRequestPayload[])
    if (requestData.length) {
      dispatch(setLoading(true))
      classifyUncategorizedInvoiceData(requestData)
        .then(() => {
          setChangedRows({})
          setReloadIndicator(true)
        })
        .catch(console.error)
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }, [changedRows, dispatch, tableData])

  useEffect(() => {
    getCategoriesList()
  }, [getCategoriesList])

  useEffect(() => {
    if (Object.keys(changedRows).length && reloadIndicator) {
      setReloadIndicator(false)
    }
  }, [changedRows, reloadIndicator])

  const context = useMemo(
    () => ({
      state: {
        selectedRows,
        isEditHidden,
        contractCategories,
        changedRows,
        hasPendingChanges,
        saveHidden,
        reloadIndicator,
        tableData
      },
      actions: {
        setSelectedRows,
        setChangedRows,
        saveChanges,
        setTableData
      }
    }),
    [
      selectedRows,
      isEditHidden,
      contractCategories,
      changedRows,
      hasPendingChanges,
      saveHidden,
      reloadIndicator,
      tableData,
      saveChanges
    ]
  )

  return (
    <PendingClassificationEditContext.Provider value={context}>
      {children}
    </PendingClassificationEditContext.Provider>
  )
}

export const usePendingClassificationEditContext = () =>
  useContext(PendingClassificationEditContext)

export default PendingClassificationEditContextProvider
