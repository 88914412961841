import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import { Popup } from '../../../../components/Popup'
import { Table } from '../../../../components/Table'
import { Input } from '../../../../components/Input'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { useDebounce } from '../../../../hooks/hooks'
import { getHSList, addHSToCC } from './api'
import { useContractCategoryDetailsContext } from '../../Providers/ContractCategoryDetailsContextProvider'
import { useContractCategoryDetailsPopup } from '../../Providers/ContractCategoryDetailsPopupProvider'
import { setLoading } from '../../../../redux/store/common/slice'
import { DEFAULT_PAGE } from '../../../../components/Table/constants'
import { BTN_TXT, PLACEHOLDERS } from '../../../../constants'
import { columns } from './constants'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import {
  AddHealthSystemData,
  AddHealthSystemParams,
  AddHealthSystemPopupProps,
  AddHealthSystemRequestData
} from './types'
import './styles.scss'

export const AddHealthSystemPopup = ({
  updateList
}: AddHealthSystemPopupProps) => {
  const [totalNumber, setTotalNumber] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)
  const [sortParams, setSortParams] = useState<AddHealthSystemRequestData>()
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const [tableData, setTableData] = useState<AddHealthSystemData[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const { details } = useContractCategoryDetailsContext().state
  const { addHealthSystemPopup } = useContractCategoryDetailsPopup()

  const dispatch = useDispatch()

  const debouncedSearch = useDebounce(searchValue, 500)

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (addHealthSystemPopup.state.visible) {
      timeoutRef.current = setTimeout(
        () => {
          getTableData({
            ...sortParams,
            page: { ...DEFAULT_PAGE }
          })
          setPageInfo({ ...DEFAULT_PAGE })
        },
        firstLoad ? 0 : 500
      )
    }
  }, [debouncedSearch, addHealthSystemPopup.state.visible])

  const getTableData = async ({
    sortField,
    sortOrder,
    page
  }: AddHealthSystemRequestData) => {
    try {
      dispatch(setLoading(true))
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
      const params: AddHealthSystemParams = {}
      if (searchValue) {
        params.search = searchValue
      }
      if (sortField) {
        if (sortOrder) {
          params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
        }
        setSortParams({
          sortField,
          sortOrder
        })
      }
      const dataPage = page ? page : pageInfo
      params.limit = dataPage.pageSize
      params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
      tableDataTokenRef.current = axios.CancelToken.source()
      const { data } = await getHSList(details.uuid, {
        cancelToken: tableDataTokenRef.current?.token,
        params
      })
      setTableData(
        data.results.map((i) => ({
          ...i,
          key: i.uuid
        }))
      )
      setTotalNumber(data.count)
      if (firstLoad) {
        setFirstLoad(false)
      }
    } finally {
      dispatch(setLoading(false))
    }
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      page
    })
    setPageInfo(page)
  }

  const onAddHSToCC = async () => {
    try {
      dispatch(setLoading(true))
      const multipleHS = selectedRowKeys.length > 1
      await addHSToCC(
        details.uuid,
        {
          health_systems: selectedRowKeys
        },
        `Health System${multipleHS ? 's' : ''} ha${
          multipleHS ? 've' : 's'
        } been added to the category.`
      )
      onCancel()
      updateList()
    } catch (e) {
      dispatch(setLoading(false))
    }
  }

  const onCancel = () => {
    addHealthSystemPopup.actions.close()
    setSelectedRowKeys([])
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page
    })
    setPageInfo(page)
  }

  const handleSelectRow = (selectedRowKeys) =>
    setSelectedRowKeys(selectedRowKeys)

  return (
    <Popup
      visible={addHealthSystemPopup.state.visible}
      onCancel={onCancel}
      width="100%"
      title="Add Health System"
      className="add-health-system-popup"
      footer={
        <>
          <Button upperCase onClick={onCancel}>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            disabled={!selectedRowKeys.length}
            onClick={onAddHSToCC}
            upperCase
          >
            {BTN_TXT.ADD}
          </Button>
        </>
      }
    >
      {!selectedRowKeys.length && (
        <Input
          className="add-health-system-popup__search allow-clear"
          propsInput={{
            allowClear: { clearIcon: <Close16 /> },
            placeholder: PLACEHOLDERS.SEARCH,
            prefix: <Search16 />,
            value: searchValue,
            onChange: (e) => setSearchValue(e.target.value)
          }}
        />
      )}
      <Table
        className="mt-4"
        dataSource={tableData}
        pageSize={pageInfo.pageSize}
        pagination={{
          pageSize: pageInfo.pageSize,
          current: pageInfo.pageNumber,
          total: totalNumber
        }}
        columns={columns}
        onChangePage={handleChangePageSize}
        onChange={handleTableChange}
        rowSelection={{
          selectedRowKeys,
          onChange: handleSelectRow
        }}
      />
    </Popup>
  )
}
