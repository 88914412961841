import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Container } from '../../components/Container/Container'
import { CONTRACT_CATEGORY_DETAILS_BREADCRUMBS } from './constants'
import { getUser } from '../../redux/store/user/getters'
import { canSeeMyContracts } from '../../helper/common'
import { Menu } from '../../components/Menu/Menu'
import useRouter from '../../hooks/useRouter'
import { useContractCategoryDetailsContext } from './Providers/ContractCategoryDetailsContextProvider'
import { safeString } from '../../helper/common'
import { contractCategoryDetailsRoute } from './routes'

export const ContractCategoryDetailsContainer = ({ children }) => {
  const router = useRouter()
  const { state, actions } = useContractCategoryDetailsContext()
  const { activeTab, tabs } = state
  const { setActiveTab } = actions
  const { tab } = useParams()
  const RenderedActions = <></>

  const user = useSelector(getUser)

  const userCanSeeMyContracts = canSeeMyContracts(user.role)

  const title = state.isNewContractCategory
    ? 'Set up category'
    : state.details.contract_category_name

  useEffect(() => {
    setActiveTab(safeString(tab || contractCategoryDetailsRoute.general))
  }, [tab])

  const RenderedMenu = useMemo(() => {
    const handleSelectTab = (e) => {
      setActiveTab(e.key)
      router.push(e.key)
    }

    return (
      <>
        {state.isNewContractCategory ? null : (
          <Menu
            mode="horizontal"
            onSelect={handleSelectTab}
            selectedKeys={[activeTab]}
            defaultSelectedKeys={[activeTab]}
            items={tabs}
          />
        )}
      </>
    )
  }, [activeTab, router, setActiveTab, tabs])

  const breadcrumbs = CONTRACT_CATEGORY_DETAILS_BREADCRUMBS(
    title,
    userCanSeeMyContracts
  )

  return (
    <Container
      title={title}
      breadcrumbs={breadcrumbs}
      actions={RenderedActions}
      menu={RenderedMenu}
    >
      {children}
    </Container>
  )
}
