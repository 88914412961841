import { TRebatesTableRequest, TRebatesTableType } from './types'
import {
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination
} from '../../../../constants'
import Fetcher from '../../../../services/Fetcher'

const fetcher = new Fetcher({})

export const getRebatesTableData: TRebatesTableRequest = async (data) =>
  fetcher.request<unknown, ResponseWithPagination<TRebatesTableType>>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.REBATES_DASHBOARD}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getHospitalFilterOptions = async () =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.REBATES_DASHBOARD}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.HOSPITAL}`,
    method: HTTP_METHODS.GET
  })

export const getStatusFilterOptions = async () =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.REBATES_DASHBOARD}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.STATUS}`,
    method: HTTP_METHODS.GET
  })

export const getVendorFilterOptions = async () =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.REBATES_DASHBOARD}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.VENDOR}`,
    method: HTTP_METHODS.GET
  })

export const getHSFilterOptions = async () =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.REBATES_DASHBOARD}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.HEALTH_SYSTEM}`,
    method: HTTP_METHODS.GET
  })
