import '../styles.scss'
import { useContractCategoryDetailsPopup } from '../../../Providers/ContractCategoryDetailsPopupProvider'
import { useState } from 'react'
import { TResponsibleUser } from '../../../../PRS/PRSDetails/types'
import { Popup } from '../../../../../components/Popup'
import { Button, BUTTON_TYPES } from '../../../../../components/Button'
import { BTN_TXT } from '../../../../../constants'
import AddResponsibleForm from './AddResponsibleForm'

const AddResponsibleModal = () => {
  const { addResponsibleUserPopup } = useContractCategoryDetailsPopup()
  const { visible, onCancel, title } = addResponsibleUserPopup.state
  const { close } = addResponsibleUserPopup.actions
  const [selectedAnalystUuid, setSelectedAnalyst] = useState<
    TResponsibleUser['uuid'] | null
  >(null)
  const [selectedLegalUuid, setSelectedLegal] = useState<
    TResponsibleUser['uuid'] | null
  >(null)
  return (
    <Popup
      visible={visible}
      onCancel={onCancel}
      className="add-responsible-popup__container"
      title={title}
      width={442}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={close} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            htmlType="submit"
            form="contract-add-responsible"
            type={BUTTON_TYPES.PRIMARY}
            upperCase
            disabled={!selectedLegalUuid && !selectedAnalystUuid}
          >
            {BTN_TXT.ADD}
          </Button>
        </>
      }
    >
      <AddResponsibleForm
        selectedAnalystUuid={selectedAnalystUuid}
        setSelectedAnalyst={setSelectedAnalyst}
        selectedLegalUuid={selectedLegalUuid}
        setSelectedLegal={setSelectedLegal}
      />
    </Popup>
  )
}

export default AddResponsibleModal
