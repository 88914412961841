import { createContext, FC, PropsWithChildren, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createContextualCan, useAbility } from '@casl/react'

import { getUser } from 'redux/store/user/getters'
import {
  ability,
  defineAbility,
  ContractCategoryAbility
} from 'features/ContractCategoryDetails/Abilities'

export const ContractCategoryDetailsAbilityContext =
  createContext<ContractCategoryAbility>(ability)

const ContractCategoryDetailsAbilityProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const user = useSelector(getUser)

  const ability = useMemo(() => defineAbility(user), [user])

  return (
    <ContractCategoryDetailsAbilityContext.Provider value={ability}>
      {children}
    </ContractCategoryDetailsAbilityContext.Provider>
  )
}

export const useContractCategoryDetailsAbility = () =>
  useAbility<ContractCategoryAbility>(ContractCategoryDetailsAbilityContext)

export const Can = createContextualCan<any>(
  ContractCategoryDetailsAbilityContext.Consumer
)

export default ContractCategoryDetailsAbilityProvider
