import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import axios, { CancelTokenSource } from 'axios'
import { BTN_TXT, PLACEHOLDERS } from 'constants/txt'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { Input } from '../../../components/Input'
import { Table } from 'components/Table'
import { AddHealthSystemPopup } from './AddHealthSystemPopup/AddHealthSystemPopup'
import { DeleteHealthSystemPopup } from './DeleteHealthSystemPopup/DeleteHealthSystemPopup'
import { useDebounce } from '../../../hooks/hooks'
import { setLoading } from '../../../redux/store/common/slice'
import { useContractCategoryDetailsContext } from '../Providers/ContractCategoryDetailsContextProvider'
import { useContractCategoryDetailsPopup } from '../Providers/ContractCategoryDetailsPopupProvider'
import { getCCHealthSystems } from '../api'
import { isTableHasParams } from 'helper/common'
import { columns } from './constants'
import { DEFAULT_PAGE } from '../../../components/Table/constants'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import {
  THealthSystemTableRequestData,
  THealthSystemUsersParams
} from '../../HealthSystemUsers/types'
import { ICCHealthSystem } from './types'
import './styles.scss'

export const HSTable = () => {
  const { addHealthSystemPopup } = useContractCategoryDetailsPopup()
  const dispatch = useDispatch()
  const { actions, state } = useContractCategoryDetailsContext()
  const { getCCDetailsAsync } = actions
  const { canManageHS } = state
  const [totalNumber, setTotalNumber] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState<ICCHealthSystem[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [tableData, setTableData] = useState<ICCHealthSystem[]>([])
  const [sortParams, setSortParams] = useState<THealthSystemTableRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })

  const { id } = useParams()

  const tableDataTokenRef = useRef<CancelTokenSource>()
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const debouncedSearch = useDebounce(searchValue, 500)
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => {
        getTableData({ ...sortParams, page: { ...DEFAULT_PAGE } })
        setPageInfo({ ...DEFAULT_PAGE })
      },
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch])

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: THealthSystemTableRequestData) => {
    dispatch(setLoading(true))
    if (firstLoad) {
      setFirstLoad(false)
    }
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: THealthSystemUsersParams = { ...filters }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    if (searchValue) {
      params.search = searchValue
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getCCHealthSystems(id as string, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp: any) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setSelectedRowKeys([])
        setSelectedRows([])
        setTotalNumber(resp.data.count)
        getCCDetailsAsync()
      })
      .finally(() => dispatch(setLoading(false)))
  }

  useEffect(() => {
    setLoading(false)
  }, [tableData[0]?.uuid as string])

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page
    })
    setPageInfo(page)
  }
  const handleSelectRow = (selectedRowKeys, selectedRow) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRow)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      page
    })
    setPageInfo(page)
  }

  const updateHSList = () =>
    getTableData({
      ...sortParams
    })

  return (
    <div className="table-wrapper">
      <div className="table-wrapper__header">
        {!!selectedRowKeys.length ? (
          <Typography.Body1
            className="table-wrapper__select-title"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            Selected {selectedRowKeys.length} of {totalNumber}
          </Typography.Body1>
        ) : (
          <Input
            className="allow-clear"
            propsInput={{
              allowClear: { clearIcon: <Close16 /> },
              className: 'rfp-hs-users_search',
              placeholder: PLACEHOLDERS.SEARCH,
              prefix: <Search16 />,
              value: searchValue,
              onChange: (e) => setSearchValue(e.target.value)
            }}
          />
        )}
        <div className="table-wrapper__header_buttons row">
          {canManageHS && (
            <>
              {!selectedRowKeys.length && (
                <Button
                  type={BUTTON_TYPES.PRIMARY}
                  icon={<Plus16 />}
                  onClick={addHealthSystemPopup.actions.open}
                  upperCase
                >
                  {BTN_TXT.ADD_SYSTEM}
                </Button>
              )}
              <DeleteHealthSystemPopup
                updateHSList={updateHSList}
                selectedHS={selectedRows}
              />
            </>
          )}
        </div>
      </div>
      <Table<ICCHealthSystem>
        dataSource={tableData}
        columns={columns}
        pageSize={pageInfo.pageSize}
        pagination={{
          pageSize: pageInfo.pageSize,
          current: pageInfo.pageNumber,
          total: totalNumber
        }}
        className="rfp-vendors-data-list-table"
        onChangePage={handleChangePageSize}
        onChange={handleTableChange}
        hasSearchOrFilters={isTableHasParams(debouncedSearch, false)}
        createButton={
          canManageHS
            ? {
                buttonText: BTN_TXT.ADD_SYSTEM,
                availability: true,
                action: addHealthSystemPopup.actions.open
              }
            : undefined
        }
        rowSelection={
          canManageHS
            ? {
                selectedRowKeys,
                onChange: handleSelectRow
              }
            : undefined
        }
      />
      <AddHealthSystemPopup updateList={updateHSList} />
    </div>
  )
}
