import { useState, useEffect } from 'react'
import cn from 'classnames'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Input } from 'components/Input'
import { Checkbox } from 'components/Checkbox'
import { Button, BUTTON_TYPES } from 'components/Button'
import { PLACEHOLDERS } from 'constants/txt'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { TDropdownProps, TDropdownItem } from './types'
import './dropdown.filter.scss'

export const Filter = <FiltersType, ApplyFiltersFunc, SearchFuncType>({
  field,
  className,
  items = [],
  asyncSearch,
  applyFilters,
  appliedFilters,
  searchCallback,
  optionFormatter,
  labelField = 'name',
  valueField = 'uuid',
  confirm,
  formatCallback
}: TDropdownProps<FiltersType, ApplyFiltersFunc, SearchFuncType>) => {
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [itemsList, setItemsList] = useState<TDropdownItem[] | undefined>(items)
  const [options, setOptions] = useState<TDropdownItem[] | undefined>()
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [appliedOptions, setAppliedOptions] = useState<string[]>([])

  useEffect(() => {
    if (asyncSearch) {
      handleFetchOptions()
    }
  }, [])

  useEffect(() => {
    if (items && !asyncSearch) {
      setItemsList(items)
    }
  }, [items])

  useEffect(() => {
    if (searchValue) {
      setOptions(
        itemsList?.filter(
          (i) => i.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
        )
      )
    } else {
      setOptions(itemsList)
    }
  }, [searchValue])

  useEffect(() => {
    if (!!appliedFilters && itemsList) {
      const newSelectedValue = !!appliedFilters[field]
        ? appliedFilters[field]
        : []
      setSelectedOptions(newSelectedValue)
      setAppliedOptions(newSelectedValue)
      const selectedFilters = itemsList.filter(
        (i) => !!newSelectedValue.find((j) => j === i.value)
      )
      const optionsList = selectedFilters.concat(
        itemsList.filter((i) => !newSelectedValue.find((j) => j === i.value))
      )
      setOptions(optionsList)
    }
  }, [appliedFilters, itemsList])

  const handleSelectOption = (option) => {
    const newSelectedOptions = [...selectedOptions]
    if (selectedOptions.find((i) => i === option)) {
      const idx = selectedOptions.findIndex((i) => i === option)
      newSelectedOptions.splice(idx, 1)
    } else {
      newSelectedOptions.push(option)
    }
    setSelectedOptions(newSelectedOptions)
  }

  const handleFetchOptions = () => {
    if (typeof searchCallback === 'function') {
      setIsLoading(true)
      searchCallback()
        .then((resp) => {
          const respData = resp.data?.results || resp.data
          if (!respData) {
            return
          }
          const formatter = optionFormatter
            ? optionFormatter
            : (i) => ({
                ...i,
                value: i[valueField],
                label: i[labelField]
              })
          const newItemsList = respData.map(formatter)
          const formattedList = formatCallback
            ? formatCallback(newItemsList)
            : newItemsList
          setOptions(formattedList)
          setItemsList(formattedList)
        })
        .finally(() => setIsLoading(false))
    }
  }

  const handleApplyFilters = () => {
    if (typeof applyFilters === 'function') {
      applyFilters(field, selectedOptions)
      confirm({ closeDropdown: true })
      setSearchValue('')
    }
  }

  return (
    <div className={cn('dropdown-filter', className)}>
      <Input
        propsInput={{
          placeholder: PLACEHOLDERS.SEARCH,
          prefix: <Search16 />,
          value: searchValue,
          disabled: isLoading,
          onChange: (e) => setSearchValue(e.target.value)
        }}
      />
      <div className="dropdown-filter__options">
        {isLoading ? (
          <Spin size="large" indicator={<LoadingOutlined spin />} />
        ) : (
          <>
            {options?.map((i) => (
              <div
                className={cn('dropdown-filter__option', {
                  'dropdown-filter__option--applied': !!appliedOptions.find(
                    (j) => j === i.value
                  )
                })}
                key={i.value}
              >
                <Checkbox
                  propsCheckbox={{
                    checked: !!selectedOptions.find((j) => j === i.value),
                    onChange: () => handleSelectOption(i.value)
                  }}
                  propsItem={{ colon: false }}
                >
                  {i.label}
                </Checkbox>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="dropdown-filter__footer">
        <Button
          type={BUTTON_TYPES.DEFAULT}
          disabled={!selectedOptions.length || isLoading}
          onClick={() => setSelectedOptions([])}
          upperCase
        >
          reset
        </Button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          disabled={isLoading}
          onClick={handleApplyFilters}
          upperCase
        >
          save
        </Button>
      </div>
    </div>
  )
}
