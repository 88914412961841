import { routes } from '../../router'

export const CONTRACT_CATEGORY_DETAILS_BREADCRUMBS = (
  contractCategoryName: string,
  userCanSeeMyContracts: boolean
) => {
  const contractsRoute = {
    children: 'Contracts',
    href: routes.contracts
  }

  const myContractsRoute = {
    children: 'My Contracts',
    href: `${routes.contracts}${routes.myContracts}`
  }

  const currentRoute = {
    children: contractCategoryName
  }

  return userCanSeeMyContracts
    ? [contractsRoute, myContractsRoute, currentRoute]
    : [contractsRoute, currentRoute]
}
