import Fetcher from '../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../constants'
import {
  TContractsCategoriesListRequest,
  TContractsCategoriesListResponse
} from './types'

const fetcher = new Fetcher({})

export const getContractsCategoriesList: TContractsCategoriesListRequest =
  async (data) =>
    fetcher.request<unknown, TContractsCategoriesListResponse>({
      url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}`,
      method: HTTP_METHODS.GET,
      ...data
    })
