import { Fragment } from 'react'
import { General } from './General/General'
import { HSTable } from './HSList/HSTable'

export const contractCategoryDetailsRoute = {
  general: 'general',
  facilityUsers: 'facility_users',
  hsList: 'hs_list'
}

export const contractCategoryDetailsRoutes = [
  {
    path: contractCategoryDetailsRoute.general,
    component: General
  },
  {
    path: contractCategoryDetailsRoute.facilityUsers,
    component: Fragment,
    disabled: true
  },
  {
    path: contractCategoryDetailsRoute.hsList,
    component: HSTable
  }
]

export const CONTRACT_CATEGORY_DETAILS_TABS = [
  {
    key: contractCategoryDetailsRoute.general,
    label: 'General'
  },
  {
    key: contractCategoryDetailsRoute.facilityUsers,
    label: 'Facility users'
  },
  {
    key: contractCategoryDetailsRoute.hsList,
    label: 'HS List'
  }
]
