import { STATUS_TYPES_CLASSNAMES } from '../../components/Status'

export const ITEM_LIST_TABS = [
  { label: 'Current', value: 'current' },
  { label: 'Pending', value: 'pending' }
]

export const ITEM_LIST_STATUS_TYPES_TO_CLASSNAME = {
  awaiting_approval: STATUS_TYPES_CLASSNAMES.YELLOW,
  replaced: STATUS_TYPES_CLASSNAMES.YELLOW,
  declined: STATUS_TYPES_CLASSNAMES.LIGHTRED,
  newly_added: STATUS_TYPES_CLASSNAMES.GRAY,
  new: STATUS_TYPES_CLASSNAMES.GRAY
}

export const ITEM_LIST_STATUS = {
  AWAITING_APPROVAL: 'awaiting_approval',
  DECLINED: 'declined',
  NEWLY_ADDED: 'newly_added',
  NEW: 'new',
  REPLACED: 'replaced'
} as const

export const ITEM_LIST_STATUS_NAME = {
  [ITEM_LIST_STATUS.AWAITING_APPROVAL]: 'Awaiting approval',
  [ITEM_LIST_STATUS.DECLINED]: 'Declined',
  [ITEM_LIST_STATUS.NEWLY_ADDED]: 'Newly added',
  [ITEM_LIST_STATUS.NEW]: 'New',
  [ITEM_LIST_STATUS.REPLACED]: 'Replaced'
} as const
