export const REPORTS_CHAT_COLORS = {
  sent: '#73A8D3',
  declined: '#FF9999',
  waiting_for_response: '#73A8D3',
  pending_for_payment: '#FAD16B',
  payment_in_progress: '#E8EBED',
  paid: '#62DA84',
  payment_failed: '#FF6666',
  disputed: '#FF9999',
  past_due: '#DC9D04'
}
