import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Sorter } from 'components/Sorter/Sorter'
import { Link } from '../../../components/Link'
import { routes } from '../../../router'
import { ICCHealthSystem } from './types'

export const columns: ColumnsType<ICCHealthSystem> = [
  {
    dataIndex: 'name',
    key: 'name',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<ICCHealthSystem>
        dataIndex="name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Health system
      </Sorter>
    ),
    render: (_, { name, uuid }) => (
      <Link to={`${routes.myCommunity}${routes.healthSystem}/${uuid}`}>
        {name}
      </Link>
    ),
    sorter: true,
    showSorterTooltip: false
  }
]
