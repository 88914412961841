import { ContractCategoryDetailsRouter } from './ContractCategoryDetailsRouter'
import { ContractCategoryDetailsContainer } from './ContractCategoryDetailsContainer'
import ContractCategoryDetailsContextProvider from './Providers/ContractCategoryDetailsContextProvider'
import ContractCategoryDetailsAbilityProvider from './Providers/ContractCategoryDetailsAbilityProvider'
import ContractCategoryDetailsPopupProvider from './Providers/ContractCategoryDetailsPopupProvider'

export const ContractCategoryDetails = () => {
  return (
    <ContractCategoryDetailsAbilityProvider>
      <ContractCategoryDetailsPopupProvider>
        <ContractCategoryDetailsContextProvider>
          <ContractCategoryDetailsContainer>
            <ContractCategoryDetailsRouter />
          </ContractCategoryDetailsContainer>
        </ContractCategoryDetailsContextProvider>
      </ContractCategoryDetailsPopupProvider>
    </ContractCategoryDetailsAbilityProvider>
  )
}
