import { AddAmendmentPopup } from 'features/ContractDetails/HSList/AddAmendment/Popup'
import { HSTable } from 'features/ContractDetails/HSList/HSTable/HSTable'
import { useHSTable } from 'features/ContractDetails/HSList/hooks/useHSTable'
import HSTableProvider from 'features/ContractDetails/HSList/Providers/HSTableProvider'
import { getContractHealthSystems } from 'features/ContractDetails/HSList/api'
import { columnsShortView, onboardedHsColumns } from './HSTable/constants'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { CONTRACT_STATUS } from '../constants'
import { useMemo } from 'react'

const HSList = () => {
  const { state } = useContractDetailsContext()
  const hsTableProps = useHSTable({ getContractHealthSystems })
  const showMark = useMemo(
    () =>
      state.details.status === CONTRACT_STATUS.ACTIVE ||
      state.details.status === CONTRACT_STATUS.EXECUTED,
    [state.details.status]
  )

  return (
    <HSTableProvider {...hsTableProps}>
      <div className="contract-hs-list">
        <HSTable
          columns={state.isOnboarded ? onboardedHsColumns : columnsShortView}
          showMark={showMark}
          {...hsTableProps}
        />
      </div>
      <AddAmendmentPopup />
    </HSTableProvider>
  )
}

export default HSList
