export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
} as const

export const API_DICTIONARY = {
  COMMUNITIES: 'communities/',
  USERS: 'users/',
  USER: 'user/',
  CURRENT: 'current/',
  HEALTH_SYSTEMS: 'health_systems/',
  HEALTH_SYSTEM: 'health_system/',
  AVAILABLE_HEALTH_SYSTEMS: 'available_health_systems/',
  HOSPITALS: 'hospitals/',
  HOSPITAL: 'hospital/',
  HOSPITAL_USERS: 'hospital_users/',
  DEPARTMENTS: 'departments/',
  CONTRACT_CATEGORIES: 'contract_categories/',
  CATEGORIES: 'categories/',
  CONTRACT_CATEGORY: 'contract_category/',
  AUTH: 'auth/',
  RESET_PASSWORD: 'reset_password/',
  SIGN_IN: 'sign_in/',
  SIGN_UP: 'sign_up/',
  VENDORS: 'vendors/',
  VENDOR: 'vendor/',
  VENDORS_SHORT: 'vendors/short_info/',
  ACCEPTED_VENDORS: 'accepted_vendors/',
  RFP: 'rfp/',
  SURVEY: 'survey/',
  CREATION: 'creation/',
  RFP_HS_USERS: 'rfp_hs_users/',
  BULK_ARCHIVE: 'bulk_archive/',
  BULK_CREATION: 'bulk_creation/',
  ITEMS: 'items/',
  RFP_ITEMS: 'rfp_items/',
  RFP_VENDOR_USERS: 'rfp_vendor_users/',
  AVAILABLE_USERS: 'available_hs_users/',
  AVAILABLE_VENDOR_USERS: 'available_vendor_users/',
  TIMELINES: 'timelines/',
  MANUAL_CREATION: 'manual_creation/',
  ARCHIVE: 'archive/',
  FILES: 'files/',
  RFP_DEFAULT_DOCUMENTS: 'rfp_default_documents/',
  RFP_VENDOR_DOCUMENTS: 'rfp_vendor_documents/',
  VENDOR_BIDDING: 'vendor_bidding/',
  STAKEHOLDER_VOTE: 'stakeholder_vote/',
  RESPONSIBLE_USERS: 'responsible_users/',
  COMMONS_REASSIGNMENT: 'commons_reassignment/',
  CALENDARS: 'calendars/',
  SCHEDULE: 'schedule/',
  CONTRACTS: 'contracts/',
  VERSION_HISTORY_DOCUMENTS: 'version_history_documents/',
  CONTRACT_DOCUMENTS: 'contract_documents/',
  CONTRACT_ATTACHMENTS: 'contract_attachments/',
  CONTRACT_EXTENSION_DOCUMENTS: 'contract_extension_documents/',
  CONTRACTING_ITEMS: 'contracting_items/',
  REBATES: 'rebates/',
  REBATE: 'rebate/',
  CONTRACT_AMENDMENT_DOCUMENTS: 'contract_amendment_documents/',
  HS_AMENDMENT: 'hs_amendment/',
  DASHBOARD: 'dashboard/',
  SAVINGS_DASHBOARD: 'savings_dashboard/',
  ONGOING_DASHBOARD: 'ongoing_dashboard/',
  COMPLIANCE_DASHBOARD: 'compliance_dashboard/',
  GROUPED_DATA: 'grouped_data/',
  EXPIRING_CONTRACT_STEPS: 'expiring_contract_steps/',
  EXPIRING_CONTRACT: 'expiring_contract/',
  EXPIRING_RFP_STEPS: 'expiring_rfp_steps/',
  OWNERS_REASSIGNMENT: 'owners_reassignment/',
  OWNER: 'owner/',
  CHATS: 'chats/',
  PVP_AVAILABLE_USERS: 'pvp_available_users/',
  GROUP_AVAILABLE_USERS: 'group_available_users/',
  VENDOR_CONTRACT_STEWARDS: 'vendor_contract_stewards/',
  AVAILABLE_OWNERS: 'available_owners/',
  MARK_AS_READ: 'mark_as_read/',
  PURCHASE_ORDER_TEMPLATE: 'purchase_order_temaplate/',
  INVOICE_DATA_TEMPLATE: 'invoice_data_template/',
  INVOICES: 'invoices/',
  PENDING_CLASSIFICATION: 'pending_classification/',
  SIMILAR_ITEMS: 'similar_items/',
  CLASSIFY: 'classify/',
  FINANCES: 'finances/',
  PURCHASE_ORDER: 'purchase_order/',
  PURCHASE_ORDERS: 'purchase_orders/',
  TOTAL_SPEND: 'total_spend/',
  TOTAL_SPEND_DATA: 'total_spend_data/',
  BULK_REMOVE: 'bulk_remove/',
  FINANCES_DASHBOARD: 'finances_dashboard/',
  CALCULATIONS: 'calculations/',
  REPORTS: 'reports/',
  ACTUAL_PAYMENTS: 'actual_payments/',
  RECALCULATION: 'recalculation/',
  REBATE_FEE: 'rebate_fee/',
  SHORT_INFO: 'short_info/',
  INFO: 'info/',
  FACILITIES: 'facilities/',
  FACILITY: 'facility/',
  CONFIRM_CREDS: 'confirm_creds/',
  CONFIRM_SMS_CODE: 'confirm_sms_code/',
  SET_PHONE_NUMBER: 'set_phone_number/',
  RESEND_SMS_CODE: 'resend_sms_code/',
  SELF_SIGN_UP: 'self_sign_up/',
  RFP_VENDOR_TEAM: 'rfp_vendor_team/',
  PAYMENTS: 'payments/',
  PAYMENTS_SESSION_LINK: 'payment_session_link/',
  CONNECT_ACCOUNT_LINK: 'connect_account_link/',
  CONNECT_ACCOUNT: 'connect_account/',
  MESSAGES: 'messages/',
  MEMBERS: 'members/',
  SUPPORT: 'support/',
  CONTACT_US: 'contact_us/',
  PRS: 'prs/',
  AVAILABLE_COMMUNITY_RESPONSIBLES: 'available_community_responsibles/',
  PRS_REQUEST_ATTACHMENTS: 'prs_request_attachments/',
  PRS_REQUEST_IMAGES: 'prs_request_images/',
  UPDATE: 'update/',
  CLINICAL_SPONSOR_USERS: 'clinical_sponsor_users/',
  VOTE: 'vote/',
  FACILITY_USERS: 'facility_users/',
  AVAILABLE: 'available/',
  CLINICAL_SPONSOR_SURVEY: 'clinical_sponsor_survey/',
  AGGREGATED_RESULTS: 'aggregated_results/',
  USER_RESULTS: 'user_results/',
  VOTED_USERS: 'voted_users/',
  TRIAL: 'trial/',
  RESULTS: 'results/',
  RCA: 'rca/',
  NEXT_STEPS: 'next_steps/',
  RESPONSIBLE_USER_REASSIGNMENT: 'responsible_user_reassignment/',
  STAKEHOLDER_REVIEWS: 'stakeholder_reviews/',
  STATISTICS: 'statistics/',
  CONTRACT: 'contract/',
  CHANGE_LOGS: 'change_logs/',
  SEARCH: 'search/',
  CHAT_ATTACHMENTS: 'chat_attachments/',
  TIMERS: 'timers/',
  ANALYSIS: 'analysis/',
  EVALUATION: 'evaluation/',
  AVALIABLE_OPPORTUNITIES: 'avaliable_opportunities/',
  COMMONS: 'commons/',
  COMMONS_RESPONSIBLE: 'commons_responsible/',
  CONTRACT_GROUP: 'contract_group/',
  CONTACT_PERSONS: 'contact_persons/',
  ACCEPT: 'accept/',
  COMMUNITY_HEALTH_SYSTEMS: 'community_health_systems/',
  REQUESTS_FOR_ANALYSIS: 'requests_for_analysis/',
  CONTRACT_EVALUATION_DOCUMENTS: 'contract_evaluation_documents/',
  EVALUATION_PO_DOCUMENTS: 'evaluation_po_documents/',
  STAKEHOLDER_ASSIGNMENT: 'stakeholder_assignment/',
  STAKEHOLDER_REVIEW: 'stakeholder_review/',
  CONTRACT_GROUPS: 'contract_groups/',
  HEADER: 'header/',
  SAVINGS: 'savings/',
  TERMINATE: 'terminate/',
  CLINICAL_REVIEW_STATISTICS: 'clinical_review_statistics/',
  CLINICAL_REVIEW_STAKEHOLDERS: 'clinical_review_stakeholders/',
  SAVINGS_GOAL: 'savings_goal/',
  HISTORY: 'history/',
  TIMEZONES: 'timezones/',
  OPTIONS: 'options/',
  EVENT_TYPE: 'event_type/',
  AVAILABLE_PARTICIPANTS: 'available_participants/',
  RESPOND: 'respond/',
  PIPELINES: 'pipelines/',
  LED_BY: 'led_by/',
  OPT_OUT: 'optout/',
  JOIN: 'join/',
  RESPONSIBLES: 'responsibles/',
  RESPONSIBLE: 'responsible/',
  RESEND: 'resend/',
  REASON: 'reason/',
  CHECK_INS: 'check_ins/',
  NOTIFICATIONS: 'notifications/',
  MARK_ALL_AS_READ: 'mark_all_as_read/',
  DEVICES: 'devices/',
  BADGE_COUNT: 'badge_count/',
  DEPARTMENT: 'department/',
  VISITOR: 'visitor/',
  QR_CODE: 'qr_code/',
  MY_COMMUNITY: 'my_community/',
  ALLOWED_ROLES: 'allowed_roles/',
  SPEND_BY_MONTH: 'spend_by_month/',
  SPEND_BY_FACILITY: 'spend_by_facility/',
  SPEND_BY_CATEGORY: 'spend_by_category/',
  REBATE_TYPE: 'rebate_type/',
  GROUPS: 'groups/',
  CONTRACT_CATEGORY_APPROVAL: 'contract_category_approval/',
  CONTRACT_ITEMS_METADATA: 'contract_items_metadata/',
  CONTRACT_ITEMS: 'contract_items/',
  RECONCILIATION: 'reconciliation/',
  RESOLVE: 'resolve',
  AWAITING_APPROVAL: 'awaiting_approval/',
  PENDING: 'pending/',
  APPROVAL: 'approval/',
  SEND_FOR_APPROVAL: 'send_for_approval/',
  CC: 'cc/',
  SPEND_BY_VENDOR: 'spend_by_vendor/',
  NON_COMPLIANT_PRODUCTS: 'non_compliant_products/',
  VENDOR_ACTIVITY: 'vendor_activity/',
  REJECTED: 'rejected/',
  NON_PO: 'non_po/',
  COMPLETE: 'complete/',
  CONFIRM_EMAIL: 'confirm_email/',
  CREATOR: 'creator/',
  SHIP_TO_ENTITY_HOSPITAL: 'ship_to_entity_hospital/',
  DOWNLOAD: 'download/',
  STATUS: 'status/',
  UPLOAD: 'upload/',
  GENERATE_FILE: 'generate_file/',
  PAYMENT_BY_STATUS: 'payment_by_status/',
  CATEGORY: 'category/',
  SETUP: 'setup/',
  REBATES_DASHBOARD: 'rebates_dashboard/'
} as const

export const ALL_ITEMS_PARAMS = {
  limit: 0,
  offset: 0
}
