import { Navigate, Route, Routes } from 'react-router-dom'
import { FINANCIAL_TABS } from '../constants'
import { FINANCIAL_ROUTES } from './routes'

export const FinancialsRouter = () => (
  <Routes>
    {FINANCIAL_ROUTES.map((route, index) => (
      <Route key={index} path={route.path} element={<route.component />} />
    ))}
    <Route index element={<Navigate to={FINANCIAL_TABS[0].key} replace />} />
  </Routes>
)
