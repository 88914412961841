import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Popup } from '../../../../components/Popup'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { Typography } from '../../../../components/Typography'
import { setLoading } from '../../../../redux/store/common/slice'
import { useContractCategoryDetailsContext } from '../../Providers/ContractCategoryDetailsContextProvider'
import { useContractCategoryDetailsPopup } from '../../Providers/ContractCategoryDetailsPopupProvider'
import { deleteCCHS } from './api'
import { BTN_TXT } from '../../../../constants'
import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import { DeleteHealthSystemPopupProps } from './types'

export const DeleteHealthSystemPopup = ({
  updateHSList,
  selectedHS
}: DeleteHealthSystemPopupProps) => {
  const { deleteHealthSystemsPopup } = useContractCategoryDetailsPopup()
  const { state, actions } = deleteHealthSystemsPopup
  const { open, close } = actions
  const { visible } = state
  const { state: stateContract } = useContractCategoryDetailsContext()
  const { details } = stateContract
  const dispatch = useDispatch()

  const handleDeleteUsers = useCallback(async () => {
    if (!details.uuid) {
      throw new Error('PRS ID not provided')
    }
    try {
      dispatch(setLoading(true))
      await deleteCCHS(
        details.uuid,
        {
          health_systems: selectedHS.map((i) => i.uuid)
        },
        `Health System${selectedHS.length > 1 ? 's' : ''} ${
          selectedHS.length > 1 ? 'have' : 'has'
        } been successfully deleted.`
      )
      updateHSList()
      closeDeletePopup()
    } finally {
      dispatch(setLoading(false))
    }
  }, [details, dispatch, updateHSList, selectedHS])

  const openDeletePopup = () => open()

  const closeDeletePopup = () => close()

  return (
    <>
      {!!selectedHS.length && (
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={openDeletePopup}
          icon={<Delete16 />}
          upperCase
          danger
        >
          {BTN_TXT.DELETE}
        </Button>
      )}
      <Popup
        visible={visible}
        onCancel={closeDeletePopup}
        title={`Delete ${
          selectedHS.length === 1 ? selectedHS[0].name : `Health System`
        }?`}
        width={442}
        footer={
          <>
            <Button
              type={BUTTON_TYPES.DEFAULT}
              onClick={closeDeletePopup}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={handleDeleteUsers}
              upperCase
              danger
            >
              {BTN_TXT.DELETE}
            </Button>
          </>
        }
      >
        <Typography.Body1>
          {selectedHS.length === 1
            ? `${selectedHS[0].name}`
            : `${selectedHS.length} Health Systems`}{' '}
          will be removed from the Contract Category. Do you really want to
          delete{' '}
          {selectedHS.length === 1
            ? `${selectedHS[0].name}`
            : `${selectedHS.length} Health Systems`}{' '}
          from the Contract Category?
        </Typography.Body1>
      </Popup>
    </>
  )
}
