import moment, { Moment } from 'moment'

import {
  IContractDetails,
  ManualContractCreationRequestData
} from 'features/ContractDetails/types'
import { IContractDetailsForm } from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/types'
import { safeNumber } from '../../../../helper/common'

export const convertDetailsToForm = (
  details: IContractDetails
): IContractDetailsForm => ({
  start_date: details.start_date ? moment(details.start_date) : null,
  finish_date: details.finish_date ? moment(details.finish_date) : null,
  admin_fee: details.admin_fee,
  expected_spend: details.expected_spend,
  expected_savings: details.expected_savings
})

export const convertFormToDetails = (
  form: IContractDetailsForm
): Partial<IContractDetails> => ({
  start_date: form.start_date
    ? moment(form.start_date).format('YYYY-MM-DD')
    : null,
  primary_finish_date: form.finish_date
    ? moment(form.finish_date).format('YYYY-MM-DD')
    : null,
  admin_fee: form.admin_fee || '0',
  expected_spend: form.expected_spend || '0',
  expected_savings: form.expected_savings || '0'
})

export const convertFormToManualCreation = (
  form: IContractDetailsForm
): Partial<ManualContractCreationRequestData> => {
  const requestData: Partial<ManualContractCreationRequestData> = {}

  if (form.start_date) {
    requestData.start_date = moment(form.start_date).format('YYYY-MM-DD')
  }
  if (form.finish_date) {
    requestData.primary_finish_date = moment(form.finish_date).format(
      'YYYY-MM-DD'
    )
  }
  if (form.admin_fee) {
    requestData.admin_fee = Number(form.admin_fee)
  }
  if (form.expected_spend) {
    requestData.expected_spend = Number(form.expected_spend)
  }
  if (form.expected_savings) {
    requestData.expected_savings = Number(form.expected_savings)
  }
  if (form.contract_category) {
    requestData.contract_category = form.contract_category
  }
  if (form.vendor_contract_steward) {
    requestData.vendor_contract_steward = form.vendor_contract_steward
  }
  if (form.creation_type) {
    requestData.creation_type = form.creation_type
  }

  return requestData
}

export const twoDecimalParser = (value: string | undefined) =>
  value ? parseFloat(value).toFixed(2) : ''

export const currencyParser = (value: string | undefined) => {
  const matches = value?.match(/.+[.]\d{0,2}/)
  const matchedValue = matches ? matches[0] : value

  return matchedValue ? matchedValue.replace(/\$\s?|(,*)/g, '') : ''
}

export const currencyParserInteger = (value: string | undefined) => {
  if (!value) {
    return ''
  }
  const integerNumber = value.replace(/[^\d.]/g, '')
  const parsedValue = parseFloat(integerNumber)
  return isNaN(parsedValue) ? '' : Math.floor(parsedValue).toString()
}

export const percentageParser = (value: string | undefined) => {
  if (!value) {
    return ''
  }
  const integerNumber = value.replace(/[^\d.]/g, '')
  const parsedValue = parseFloat(integerNumber)
  const isInteger = Number.isInteger(parsedValue)
  return isNaN(parsedValue)
    ? ''
    : isInteger
    ? parsedValue.toString()
    : parsedValue.toFixed(2).toString()
}

export const currencyFormatter = (value: string | number | undefined) =>
  String(value).replace(/\B(?=(\d{3})+(?!\d).?)/g, ',')

export const getCurrencyValue = (value: number) => {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' })
  return formatter.format(value)
}

export const disabledDateSameOrBefore =
  (prevDate: Moment | null) => (current: Moment | null) =>
    prevDate && current ? moment(prevDate).isSameOrAfter(current) : false

export const disabledDateBefore =
  (prevDate: Moment | null) => (current: Moment | null) =>
    prevDate && current ? moment(prevDate).isAfter(current) : false

export const sumPercentage = (firstValue: string, secondValue: string) =>
  (
    (Number(firstValue) * 100) /
    (Number(firstValue) + Number(secondValue))
  ).toFixed(2)

export const sumPercentageTotal = (firstValue: string, total: number) =>
  ((Number(firstValue) * 100) / Number(total)).toFixed(2)

export const totalSpendCounting = (value: number) => ({
  total: value,
  third: (value * 3) / 4,
  second: (value * 2) / 4,
  first: (value * 1) / 4,
  zeroth: 0
})

export const getHeight = (num: string, max: number) => {
  return (107 * +num || 0) / safeNumber(ceilIntNumber(max))
}

export const convertToFixed = (value: string | number) =>
  Number(value).toFixed(2)

export const ceilIntNumber = (value: number) => Math.ceil(value / 1000) * 1000
