import { CONTRACT_STATUS } from '../../../constants'
import { ROLES } from '../../../../Permission'
import { TDetectPermissions } from './types'

export const isContractActive = (status: string) =>
  status !== CONTRACT_STATUS.TERMINATED && status !== CONTRACT_STATUS.DECLINED

export const isVendorReassignmentAllowed = ({
  currentUser,
  user,
  details
}: TDetectPermissions) =>
  currentUser.role === ROLES.VENDOR_CONTRACT_ADMIN &&
  isContractActive(details.status) &&
  user.role === ROLES.VENDOR_CONTRACT_STEWARD

export const isCommonsReassignmentAllowed = ({
  currentUser,
  user,
  details
}: TDetectPermissions) =>
  currentUser.role === ROLES.COMMONS_VICE_PRESIDENT &&
  isContractActive(details.status) &&
  (user.role === ROLES.COMMONS_VICE_PRESIDENT ||
    user.role === ROLES.COMMONS_CONTRACT_STEWARD)

export const isCommunityReassignmentAllowed = ({
  currentUser,
  user,
  details
}: TDetectPermissions) =>
  (currentUser.role === ROLES.VICE_PRESIDENT ||
    currentUser.role === ROLES.COMMUNITY_PRESIDENT) &&
  isContractActive(details.status) &&
  (user.role === ROLES.COMMUNITY_PRESIDENT ||
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.CONTRACT_STEWARD)
