import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import './style.scss'

import { ReactComponent as ErrorIcon } from 'assets/svg/ErrorIcon.svg'
import { ReactComponent as WarningIcon } from 'assets/svg/WarningIcon.svg'
import { ReactComponent as SuccessIcon } from 'assets/svg/SuccessIcon.svg'
import cn from 'classnames'
import Typography from '../../../../components/Typography/Typography'
import { useMemo } from 'react'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { usePrsDetailsAbility } from '../Providers/PrsDetailsAbilityProvider'
import { PRS_STATUSES } from '../../../../pages/ProductRequests/constants'

const ReasonComponent = () => {
  const { state } = usePRSDetailsContext()
  const { data, isFacilityUser, isClinicalSponsor } = state

  const ability = usePrsDetailsAbility()

  const canSeeWarningLabelCS = useMemo(() => {
    return ability.can(ACTIONS.SEE, subject(SUBJECTS.LABEL, { ...data }))
  }, [ability, data])

  const canSeeCSApprove = useMemo(() => {
    return (
      ability.can(
        ACTIONS.SEE,
        subject(SUBJECTS.CS_APPROVAL_LABEL, { ...data })
      ) &&
      !data?.trial &&
      !data?.rca_decision
    )
  }, [ability, data])

  const canSeeWarningLabelTrial = useMemo(() => {
    return (
      !data?.trial?.review_decision &&
      ability.can(
        ACTIONS.VIEW,
        subject(SUBJECTS.PRE_APPROVAL_LABEL, { ...data })
      )
    )
  }, [ability, data])
  const canSeeTrialRequestedNote = useMemo(() => {
    return (
      data?.trial?.message_for_vendor &&
      ability.can(
        ACTIONS.VIEW,
        subject(SUBJECTS.TRIAL_REQUESTED_NOTE, { ...data })
      )
    )
  }, [ability, data])

  const canSeeRcaApproveLabel = useMemo(() => {
    return (
      ability.can(
        ACTIONS.VIEW,
        subject(SUBJECTS.RCA_APPROVE_LABEL, { ...data })
      ) && data.rca_decision
    )
  }, [ability, data])

  return (
    <>
      {(canSeeWarningLabelCS ||
        (canSeeWarningLabelTrial && isFacilityUser) ||
        (data?.trial?.review_decision &&
          data?.trial?.status === PRS_STATUSES.TRIAL_PRE_APPROVAL) ||
        canSeeRcaApproveLabel ||
        canSeeCSApprove) && (
        <div
          className={cn(
            `prs-details-reason row mt-24 space-between ${data?.trial?.review_decision}`,
            {
              decline:
                data?.decline_reason ||
                (canSeeRcaApproveLabel && data.rca_decision === 'rca_declined'),
              info: canSeeWarningLabelCS || canSeeWarningLabelTrial,
              approved:
                (canSeeRcaApproveLabel &&
                  data.rca_decision === 'rca_approved') ||
                canSeeCSApprove
            }
          )}
        >
          {canSeeWarningLabelTrial && isFacilityUser && (
            <>
              <WarningIcon />
              <div className="prs-details-reason__content column">
                <Typography.Body2 className="prs-details-reason__content__header">
                  Trial pre-approval
                </Typography.Body2>
                <Typography.Caption className="prs-details-reason__content__text mt-8">
                  {data?.trial?.created_by?.first_name +
                    ' ' +
                    data?.trial?.created_by?.last_name +
                    ' '}
                  has requested a trial. Please, respond whether you need trial
                  to make a decision about the product in future voting.
                </Typography.Caption>
                <Typography.Caption className="prs-details-reason__content__text mt-8">
                  {data?.trial?.message_for_stakeholder}
                </Typography.Caption>
              </div>
            </>
          )}
          {canSeeWarningLabelCS && (
            <>
              <WarningIcon />
              <div className="prs-details-reason__content column">
                <Typography.Body2 className="prs-details-reason__content__header">
                  PRS approval
                </Typography.Body2>
                <Typography.Caption className="prs-details-reason__content__text mt-8">
                  {isClinicalSponsor
                    ? 'You have been assigned as the Clinical Sponsor for this PRS.\n' +
                      '                  Please review the details, complete the required clinical\n' +
                      '                  information, and approve the request to proceed.'
                    : `Vendor has listed ${data.clinical_sponsor_stakeholder?.first_name} ${data.clinical_sponsor_stakeholder?.last_name} as Clinical Sponsor for this PRS. If
                  this is really you, please, fill in clinical information below
                  and approve the request.`}
                </Typography.Caption>
              </div>
            </>
          )}
          {data?.trial?.review_decision &&
            data?.trial?.status === PRS_STATUSES.TRIAL_PRE_APPROVAL && (
              <>
                {data?.trial?.review_decision === 'approved' ? (
                  <SuccessIcon />
                ) : (
                  <ErrorIcon />
                )}
                <div className="prs-details-reason__content column">
                  <Typography.Body2 className="prs-details-reason__content__header">
                    Trial request has been{' '}
                    {data?.trial?.review_decision === 'approved'
                      ? 'approved'
                      : 'rejected'}
                    .
                  </Typography.Body2>
                </div>
              </>
            )}
          {canSeeCSApprove && (
            <>
              <SuccessIcon />
              <div className="prs-details-reason__content column">
                <Typography.Body2 className="prs-details-reason__content__header">
                  The request has been approved by{' '}
                  {isClinicalSponsor
                    ? 'you'
                    : `${data.clinical_sponsor_stakeholder?.first_name} ${data.clinical_sponsor_stakeholder?.last_name}`}
                  .
                </Typography.Body2>
              </div>
            </>
          )}
          {canSeeRcaApproveLabel && (
            <>
              {data.rca_decision === 'rca_approved' ? (
                <SuccessIcon />
              ) : (
                <ErrorIcon />
              )}
              <div className="prs-details-reason__content column">
                {data.rca_decision === 'rca_approved' ? (
                  <>
                    <Typography.Body2 className="prs-details-reason__content__header">
                      RCA has approved the product request
                    </Typography.Body2>
                  </>
                ) : (
                  <>
                    <Typography.Body2 className="prs-details-reason__content__header">
                      RCA has declined the product request
                    </Typography.Body2>
                    <Typography.Caption className="prs-details-reason__content__text mt-8">
                      {data.rca_decline_reason}
                    </Typography.Caption>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
      {canSeeTrialRequestedNote && (
        <div
          className={cn(`prs-details-reason row mt-24 space-between`, {
            info: canSeeTrialRequestedNote
          })}
        >
          <>
            <WarningIcon />
            <div className="prs-details-reason__content column">
              <Typography.Body2 className="prs-details-reason__content__header">
                Trial request
              </Typography.Body2>
              <Typography.Caption className="prs-details-reason__content__text mt-8">
                {data?.trial?.message_for_vendor}
              </Typography.Caption>
            </div>
          </>
        </div>
      )}
      {data?.decline_reason &&
        !canSeeWarningLabelCS &&
        !canSeeWarningLabelTrial && (
          <div
            className={cn(`prs-details-reason row mt-24 space-between`, {
              decline: data?.decline_reason
            })}
          >
            <>
              <ErrorIcon />
              <div className="prs-details-reason__content column">
                <Typography.Body2 className="prs-details-reason__content__header">
                  Request declined
                </Typography.Body2>
                <Typography.Caption className="prs-details-reason__content__text mt-8">
                  {data.decline_reason}
                </Typography.Caption>
              </div>
            </>
          </div>
        )}
    </>
  )
}

export default ReasonComponent
