import cn from 'classnames'
import { Typography } from 'components/Typography'
import { currencyFormatter } from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { FinancialsChartLegendPartProps } from './types'
import './styles.scss'

export const FinancialsChartLegendPart = ({
  color,
  name,
  price,
  children
}: FinancialsChartLegendPartProps) => (
  <div
    className="legend__part row space-between align-center gap-4"
    data-testid="legendPartRow"
  >
    <div className="legend__title row align-center gap-8">
      <div className={cn('square', color)} />
      <Typography.Headline6 className="row align-center" weight="normal">
        {name}
        {children}
      </Typography.Headline6>
    </div>
    <Typography.Headline6 className="legend__price" weight="semi-bold">
      {`$${currencyFormatter(Number(price)?.toFixed(2))}`}
    </Typography.Headline6>
  </div>
)
