import moment from 'moment'

export const BTN_TXT = {
  FORGOT_PASSWORD: 'Forgot password',
  RESET_PASSWORD: 'Reset password',
  SIGN_UP: 'Sign up',
  LOG_IN: 'Log in',
  SEND: 'Send',
  NEXT: 'Next',
  SAVE: 'save',
  CREATE_USER: 'Create user',
  EDIT: 'edit',
  ARCHIVE: 'archive',
  CANCEL: 'cancel',
  CONTINUE: 'Continue',
  ADD_HEALTH_SYSTEM: 'Add health system',
  DELETE: 'delete',
  ADD_HOSPITAL: 'Add hospital',
  ADD_COMMUNITY: 'Add community',
  ADD: 'Add',
  BACK: 'back',
  CREATE_AND_ADD_TO_LIST: 'Create and add to list',
  CREATE_RFP: 'create rfp',
  ADD_USER: 'add user',
  BACK_TO_EDIT: 'back to edit',
  DISCARD_CHANGES: 'discard changes',
  CREATE: 'create',
  ACTIVATE_BACK: 'Activate back',
  ADD_ITEMS: 'Add items',
  SEND_RFP_AND_START_BIDDING: 'Send RFP and start bidding',
  ADD_VENDOR: 'Add vendor',
  UPLOAD: 'Upload',
  UPLOAD_LOP: 'Upload LOP',
  UPLOAD_RFP_FILE: 'Upload RFP file',
  UPLOAD_RFP_SUMMARY: 'Upload Summary',
  UPLOAD_QUESTIONS: 'Upload questions',
  CANCEL_RFP: 'Cancel rfp',
  SEND_RFP: 'Send rfp',
  SEND_TO_COMMONS: 'Send to commons',
  SEND_SURVEY: 'Send Survey',
  BACK_TO_RFP_DETAILS: 'Back to rfp details',
  CREATE_SURVEY: 'Create survey',
  EDIT_SURVEY: 'Edit survey',
  OPEN_SURVEY: 'Open survey',
  VIEW_SURVEY: 'View survey',
  UPLOAD_PRICE_FILE: 'Upload price file',
  UPLOAD_GENERAL_QUESTIONS: 'Upload general questions',
  AWARD_VENDORS: 'award vendors',
  AWARD_VENDORS_AND_ARCHIVE_RFP: 'Award Vendors and Archive RFP',
  START_VENDORS_SELECTION: 'Start Vendors selection',
  SUBMIT_SURVEY: 'Submit results',
  SUBMIT: 'Submit',
  ACCEPT: 'Accept',
  DECLINE: 'Decline',
  ACCEPT_RFP: 'Accept RFP',
  DECLINE_RFP: 'Decline RFP',
  START_BIDDING_QA: 'Start bidding q&A',
  START_BIDDING: 'Start bidding',
  START_PRICE_FILE_ANALYSIS: 'Start price file analysis',
  ADD_RESPONSIBLE: 'Add responsible',
  DELETE_FROM_RFP: 'Delete from RFP',
  SHOW_VERSION_HISTORY: 'Show version history',
  SHOW_EXTENSION_HISTORY: 'Show extension history',
  CREATE_CONTACT: 'create contract',
  CREATE_VENDOR: 'Create vendor',
  ADD_EVENT: 'Add event',
  TODAY: 'Today',
  TERMINATE: 'terminate',
  DELETE_FROM_CONTRACT: 'Delete from contract',
  BACK_TO_DETAILS: 'Back to details',
  TERMINATE_CONTRACT: 'terminate contract',
  START_SIGN_PROCESS: 'Start sign process',
  CONTRACT_SIGNED: 'Contract signed',
  EXTEND_CONTRACT: 'Extend contract',
  UPDATE_EXTENSION: 'Update extension',
  RENEW_FINISH_DATE: 'Renew finish date',
  EXTEND: 'Extend',
  BACK_TO_RENEWAL_FORM: 'Back to renewal form',
  RENEW_DATE: 'Renew date',
  ADD_GROWTH_CONDITION: 'Add growth condition',
  UPLOAD_ITEM_LIST: 'upload item list',
  UPDATE_ITEM_LIST: 'update item list',
  ADD_SYSTEM: 'Add system',
  SAVE_N_ADD_SYSTEM: 'Save & Add System',
  REASSIGN: 'Reassign',
  CREATE_GROUP_CHAT: 'Create group chat',
  START_PRIVATE_CHAT: 'Start private chat',
  CREATE_CHAT: 'Create chat',
  ADD_MEMBERS: 'Add members',
  DELETE_USER_FROM_CHAT: 'Delete user from the chat',
  ADD_TO_CHAT: 'Add to chat',
  ASSIGN_TO_CONTRACT_STEWARD: 'Assign to Contract Steward',
  ASSIGN_AND_CREATE_CONTRACT: 'Assign and create contract',
  UPLOAD_PURCHASE_ORDERS: 'Upload purchase orders',
  UPLOAD_INVOICES: 'Upload invoices',
  UPLOAD_PURCHASE_ORDER: 'Upload purchase order',
  UPDATE: 'Update',
  ACCEPT_AND_PAY: 'Accept and pay',
  RESEND: 'Resend',
  RESEND_REPORT: 'Resend report',
  UPDATE_TIME: 'Update time',
  UPDATE_TIME_AND_SEND_SURVEY: 'Update time and send Survey',
  RESEND_CODE: 'Resend code',
  DECLINE_CONTRACT: 'Decline contract',
  ADD_BANK_ACCOUNT: 'Add bank account',
  PAY: 'Pay',
  LINKED_ACCOUNT: 'linked account',
  REQUEST_ACCESS: 'Request access',
  CONTACT_US: 'Contact Us',
  CREATE_PRS: 'Create PRS',
  BACK_TO_PRS_DETAILS: 'Back to PRS details',
  RESEND_PRS: 'Resend prs',
  SEND_PRS: 'Send Prs',
  CANCEL_PRS: 'Cancel Prs',
  OPEN_PRS_AND_START_REVIEW: 'Open PRS and start review',
  ADD_TEAM_MEMBERS: 'Add Team Members',
  ADD_OPTION: 'Add option',
  ADD_QUESTION: 'Add question',
  OTHER: 'Other',
  ADD_CLINICAL_SPONSOR: 'add clinical sponsor',
  ASSIGN_CLINICAL_SPONSOR: 'assign clinical sponsor',
  REASSIGN_CLINICAL_SPONSOR: 'reassign clinical sponsor',
  SEND_FOR_SPONSOR_APPROVAL: 'Send for sponsor approval',
  SEND_FOR_APPROVAL: 'Send for approval',
  APPROVE_REQUEST: 'Approve request',
  START_REVENUE_ASSESSMENT: 'start revenue assessment',
  RUN_THE_STEP: 'Run the step',
  CREATE_RCA: 'create RCA',
  RUN_NEW_STEP: 'Run new step',
  OPEN_MY_RESULTS: 'Open my results',
  REQUEST_TRIAL: 'Request trial',
  OPEN_RESULTS: 'Open results',
  SAVE_RESPONSE: 'save response',
  PREVIOUS: 'previous',
  OPEN_MY_RESPONSE: 'Open my response',
  OPEN_RESPONSE: 'Open response',
  CREATE_CONTRACT: 'Create contract',
  BULK_CREATE: 'Bulk create',
  ADD_CONTRACT: 'Add contract',
  APPROVE: 'approve',
  APPROVE_PRS: 'Approve PRS',
  RESPOND: 'respond',
  REJECT_TRIAL: 'reject Trial',
  APPROVE_TRIAL: 'Approve trial',
  COMPLETE_VOTING: 'Complete voting',
  COMPLETE_TRIAL_REQUEST: 'Complete trial request',
  COMPLETE: 'Complete',
  CONFIRM_TRIAL: 'Confirm trial',
  BACK_TO_PAGE: 'Back to page',
  SEND_TO_VENDOR: 'Send to vendor',
  START: 'Start',
  INVITE_USER_CREATE_CONTRACT: 'invite user & create contract',
  SEND_TRIAL_REQUEST_TO_VENDOR: 'Send trial request to vendor',
  EVALUATE: 'Evaluate',
  RUN_CLINICAL_REVIEW: 'Run clinical review',
  SEND_TO_REVIEW: 'Send to review',
  CREATE_STAKEHOLDER: 'create Stakeholder',
  TERMINATE_EVALUATION: 'Terminate evaluation',
  ADD_PERIOD: 'Add period',
  MAYBE: 'Maybe',
  CREATE_PIPELINE_RECORD: 'Create pipeline record',
  PARTICIPATE: 'Participate',
  OPT_OUT: 'opt out',
  JOIN: 'Join',
  RESEND_BID: 'Resend Bid',
  ADD_COMMONS_RESPONSIBLE: 'add commons responsible',
  MARK_ALL_AS_READ: 'Mark all as read',
  RESEND_VERIFICATION_LINK: 'Resend verification link',
  FILTERS: 'Filters',
  RESET_FILTERS: 'Reset filters',
  SET_ITEM_DETAILS: 'set item details',
  APPLY: 'Apply',
  GENERATE_QR_CODE: 'Generate QR CODE',
  UPLOAD_INTENT_TO_BID_LETTER: 'Upload Intent to Bid Letter',
  UPLOAD_GENERAL_QUESTIONNAIRE: 'Upload General Questionnaire',
  UPLOAD_RFP_WORKBOOK: 'Upload RFP Workbook',
  TEMPLATE: 'TEMPLATE',
  ADD_CONTRACT_CATEGORY: 'Add contract category',
  DOWNLOAD_TEMPLATE: 'download template',
  EXPORT_INVOICES: 'Export rejected invoices',
  SORT: 'Sort',
  EXPORT_REJECTED_INVOICES: 'Export rejected invoices',
  SAVE_CHANGES: 'Save changes',
  DOWNLOAD_CURRENT_ITEM_LIST: 'Download current item list',
  NONE_DEFAULT: 'None (default)',
  DOCUMENT_KEY_ASC: 'Document key: Ascending',
  DOCUMENT_KEY_DESC: 'Document key: Descending',
  INVOICE_DATE_ASC: 'Invoice date: Ascending',
  INVOICE_DATE_DESC: 'Invoice date: Descending',
  RECONCILE_DUPLICATES: 'reconcile duplicates',
  SEE_ITEM_LIST: 'see item list',
  CHAT_DETAILS: 'Chat details',
  DELETE_CHAT: 'Delete chat',
  VIEW_INVOICE_HISTORY: 'View invoice history',
  ADD_LOP_DOCUMENT: 'Add LoP document',
  SET_UP_CATEGORY: 'set up category'
}

export const PLACEHOLDERS = {
  EMAIL: 'example@email.com',
  SEARCH_USERS: 'Search by user name or heath system',
  SEARCH_RFP_USERS: 'Search by user name or email',
  SEARCH_RFP_LIST: 'Search by RFP name',
  SEARCH_BY_VENDOR_OR_USERNAME: 'Search by vendor or user name',
  SEARCH: 'Search',
  PLEASE_SELECT: 'Please select',
  SELECT_CATEGORY: 'Please select category',
  SEARCH_BY_CHAT_NAME: 'Search by chat name',
  KEEP_CURRENT_VALUE: 'Keep current value',
  FIND_USERS_EOU_WOULD_LIKE_TO_ADD: 'Find users you would like to add',
  SEARCH_USERS1: 'Search users',
  SEARCH_BY_PRS_COMMUNITY_NAME: 'Search by PRS, community name',
  SEARCH_BY_PRS_VENDOR_NAME: 'Search by PRS, vendor name',
  OTHER: 'Other',
  SEARCH_USERS_BY_NAME: 'Search by user name',
  OTHER_TYPE: 'Other type',
  HH_MM: 'hh:mm',
  MM_DD_YYYY: 'mm/dd/yyyy',
  SEARCH_LOCATION: 'Search location',
  SEARCH_BY_HEALTH_SYSTEM: 'Search by Health System',
  ALL: 'All'
}

export const LABELS = {
  EMAIL: 'Email',
  PASSWORD: 'Password',
  CREATE_PASSWORD: 'Create password',
  REPEAT_PASSWORD: 'Repeat password',
  MESSAGE: 'Message',
  HOSPITAL_NAME: 'Hospital name',
  TAX_ID: 'Tax ID',
  PHONE_NUMBER: 'Phone number',
  STREET: 'Street',
  CITY: 'City',
  STATE: 'State',
  ZIP_CODE: 'Zip code',
  DEPARTMENTS: 'Departments',
  SYSTEM_NAME: 'System name',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  POSITION: 'Position',
  CONTRACT_CATEGORIES: 'Contract categories',
  NAME: 'Name',
  HEALTH_SYSTEM: 'Health system',
  VICE_PRESIDENT: 'Vice president',
  VENDOR: 'Vendor',
  CONTRACT_CATEGORY: 'Contract category',
  VENDOR_CONTRACT_STEWARD: 'Vendor Contract Steward',
  CONTRACT_STEWARD: 'Contract Steward',
  LEGAL: 'Legal',
  ANALYST: 'Analyst',
  USER: 'User',
  ROLE: 'Role',
  HOSPITAL: 'Hospital',
  HOSPITALS: 'Hospitals',
  SELECT_VENDORS_FOR_SURVEY: 'Select vendors for survey',
  START_DATE: 'Start date',
  FINISH_DATE: 'Finish date',
  PRICING_EFFECTIVE_DATE: 'Pricing effective date',
  ADMIN_FEE: 'Admin fee,%',
  EXPECTED_SPEND: 'Expected spend,$',
  EXPECTED_SAVINGS: 'Expected savings,$',
  COMPLIANCE_REQUIREMENT: 'Compliance requirement,%',
  BASELINE_SPEND: 'Baseline spend, $',
  HOSPITAL_BASELINE_SPEND: 'Hospital baseline spend, $',
  REBATE_FIXED_AMOUNT: 'Amount, $',
  REBATE_PERCENTAGE: 'Percentage, %',
  REBATE_AMOUNT: 'Rebate amount, %',
  REBATE_AMOUNT_FROM: 'Amount from, $',
  REBATE_AMOUNT_TO: 'Amount to, $',
  REBATE_TYPE: 'Rebate type',
  PERIOD_THRESHOLD: 'Period threshold, months',
  USAGE: 'Usage',
  PRICE: 'Price, $',
  TOTAL_COST: 'Total cost, $',
  UNSPSC_CODE: 'UNSPSC Code',
  PURCHASE_ORDERS: 'Purchase order date',
  FACILITY: 'Facility',
  COMMUNITY: 'Community',
  COMMUNITY_NAME: 'Community name',
  DESCRIPTION: 'Description',
  CHAT_NAME: 'Chat name',
  ASSIGN_TO_CONTRACT_STEWARD: 'Assign to Contract Steward',
  VENDOR_NAME: 'Vendor name',
  VENDORS_PHONE_NUMBER: 'Vendor’s phone number',
  ORGANIZATION: 'Organization',
  YOUR_INQUIRY: 'Your inquiry',
  SURNAME: 'Surname',
  PRODUCT_NAME: 'Product name',
  ANTICIPATED_MONTHLY_USAGE: 'Anticipated monthly usage',
  PRODUCT_DESCRIPTION: 'Product description',
  JUSTIFICATION: 'Justification',
  HEALTH_SYSTEMS: 'Health systems',
  APPLY_TO_ALL_HOSPITALS: 'Does this apply to all Hospitals?',
  RESPONSIBLE_COMMUNITY_USER: 'Responsible community user',
  REQUESTER_NAME: 'Requester name',
  VENDOR_CONTACTS: 'Vendor contacts',
  QUESTION: 'Question',
  OPTION: 'Option',
  OTHER: 'Other',
  ANSWER: 'Answer',
  CLINICAL_SPONSOR_USER: 'Clinical Sponsor user (stakeholder) ',
  RCA_REPRESENTATIVE: 'RCA representative',
  RFP_TYPE: 'RFP type',
  PARTICIPATION_TYPE: 'Participation type',
  SAVINGS_GOALS: 'Savings goals',
  SAVING_ESTIMATES: 'Saving estimates',
  CURRENT_COMPLIANCE: 'Current compliance',
  TYPE_OF_EVENT: 'Type of event',
  LOCATION: 'Location',
  END_DATE: 'End date',
  ADD_GUESTS: 'Add guests',
  START_TIME: 'Start time',
  END_TIME: 'End time',
  TIME_ZONE: 'Time zone',
  ORGANIZER: 'Organizer',
  LED_BY: 'Led by',
  CONTRACT_CATEGORY_NAME: 'Contract category name',
  RESPONSIBLE: 'Responsible',
  EST_BID_START_DATE: 'Estimated bid start date',
  EST_SAVINGS: 'Estimated savings, %',
  ESTIMATED_BIT_START_DATE: 'Estimated bid start date',
  ESTIMATED_SAVINGS: 'Estimated savings, %',
  PORTFOLIO: 'Portfolio',
  CURRENT_VENDOR: 'Current vendor',
  EXPIRATION_DATE: 'Expiration date',
  REBATES: 'Rebates',
  REASON: 'Reason',
  TITLE: 'Title',
  YEARS_OF_SERVICE: 'Years of service',
  VENDOR_PREFERENCES: 'Vendor preferences',
  HOBBIES: 'Hobbies',
  VENDOR_ORG_NAME: 'Vendor organization name',
  VISITOR_NAME: 'Visitor name',
  CATEGORIES: 'Categories',
  CATEGORY: 'Category',
  FACILITIES: 'Facilities',
  DEPARTMENT: 'Department'
}

export const VALIDATION_MESSAGES = {
  REQUIRED: 'REQUIRED',
  PASSWORD: 'PASSWORD',
  DELETE_MEMBERS: 'Member has been removed from chat.',
  PASSWORD_UPDATE_SUCCESS: 'Your password has been changed successfully.',
  MATCH_PASSWORDS: 'MATCH_PASSWORDS',
  VENDOR_ACCEPTANCE_STEP_HAS_BEEN_STARTED:
    'Vendor acceptance step has been started.',
  THE_TIMEFRAMES_FOR_RFP_STEPS_HAVE_BEEN_UPDATED:
    'The timeframes for RFP steps have been updated.',
  CONTRACT_HAS_BEEN_SAVED: 'Contract has been saved.',
  TEAM_MEMBER_USER_ADDED: 'Team member has been added',
  COMMONS_MEMBER_USER_ADDED: 'Commons responsible has been added',
  TEAM_MEMBERS_USER_ADDED: 'Team members have been added',
  COMMONS_MEMBERS_USER_ADDED: 'Commons responsibles have been added',
  TEAM_MEMBER_USER_DELETED: 'Team member has been deleted',
  CHAT_DOESNT_EXIST: 'You can not send the message. This chat does not exist',
  REBATE_INTERSECTION:
    'Incorrect amount conditions. \n' +
    'Amount from and amount to conditions \n' +
    'should not be crossed.',
  BULK_CREATE_SUCCESS: 'Contracts creation is in progress.',
  SM0009: 'has been successfully',
  SM0017:
    'You are not assigned to contract categories. \n' +
    'Please, contact your Vice President',
  SM0017_1:
    'You are not assigned to contract categories. \n' +
    'Please, contact your Contract Admin',
  SM0024: 'RFP document is not attached. \n' + 'Please, upload the file.',
  SM0026:
    'RFP has been accepted. \n' +
    'You will receive notifications regarding RFP status changes.',
  SM0027:
    'RFP has been declined. \n' +
    'You won’t receive any notifications about it.',
  SM0029:
    'Survey is not created. \n' + 'Please create a survey to start voting.',
  SM0030: 'Time less than one hour from now can’t be selected.',
  SM0031: 'Health System has been added to the contract.',
  SM0031_PLURAL: 'Health Systems have been added to the contract.',
  SM0032:
    'Executive summary is not uploaded. \n' +
    'Please upload the file to start voting.',
  SM0036:
    'General questions document is not attached.\n' +
    'Please, upload the file.',
  SM0038: 'Wrong document format.',
  SM0039: 'Please, complete all required questions.',
  SM0040: 'Survey has been submitted. \n' + 'Thank you for voting!',
  SM0042:
    'RFP categories are not selected. \n' +
    'Please, select awarded Vendors and connect them with all categories.',
  SM0043:
    'Awarded vendors have been selected. \n' +
    'Vendors will receive notifications.',
  SM0044: 'The file has been uploaded.',
  SM0046:
    'Uploading failed due to multiple errors.\n' +
    'Please, check the information in the document.',
  SM0047: 'Rebate has been saved.',
  SM0054: 'Members have been added to the Chat',
  SM0055: 'Vendor and Contract Admin have been \n' + 'created.',
  SM0055_1: 'Guest vendor has been created.',
  SM0061:
    'The file cannot be uploaded due to errors occurred during validation. You will receive an email with the errors found.',
  SM0062: 'The report has been',
  SM0069: 'Purchase order has been deleted.',
  SM0070: 'Purchase order data has been saved.',
  SM0066: 'The report has been resent.',
  SM0067:
    'The contract cannot be signed. Please make sure the information is provided for all tabs.',
  SM00901: 'Contract has been successfully moved to sign process.',
  SM00902: 'Contract has been successfully signed.',
  SM00101:
    'Overall deadline cannot happen before subsequent step deadline. Please, adjust timers.',
  SM00123: 'Savings target has been updated.',
  SM00128: 'Clinical review response has been submitted',
  SM00131: 'Contracts sent for opportunity analysis step',
  SM00136: 'Contract category has been accepted.',
  SM00119: 'The bid has been resent to Vendor.',
  SM0144: 'New records have been added to the existing item list '
}

export const TOOLTIP = {
  RESET_TO_DEFAULT_VALUES: 'Reset to default values',
  OPEN_ACCOUNT_DETAILS: 'Open account details',
  ONLY_ONE_HEALTH_SYSTEM_CAN_BE_SELECTED:
    'Only one Health System can be selected',
  AT_LEAST_ONE_HOSPITAL_SHOULD_BE_ASSIGNED:
    'At least one hospital should be assigned',
  STEP_HAS_PASSED: 'Step has passed. You no longer can change it.',
  REPLY_TO_MESSAGE: 'Reply to message',
  CANT_BE_SELECTED_AS_PAYMENT_HAS_BEEN_ALREADY: `Can't be selected as payment has been already processed`,
  THE_REPORT_WILL_BE_REGENERATED_AND_RESEND:
    'The report will be regenerated and resend',
  ONLY_ONE_RESPONSIBLE_CONTRACT_STEWARD_CAN_BE_SELECTED:
    'Only one responsible Contract Steward can be selected',
  ONLY_ONE_OPTION_PER_DUPLICATE_GROUP_IS_SELECTABLE:
    'Only one option per duplicate group is selectable',
  ASSIGN_CLINICAL_SPONSOR_TO_PROCEED: 'Assign clinical sponsor to proceed',
  SURVEY_IS_NOT_CREATED: 'Please create survey on voting tab to proceed.',
  ONE_STAKEHOLDER:
    'Please, wait for at least 1 survey response to be provided.',
  FACILITY_USERS_ARE_NOT_PROVIDED:
    'Please, add at least 1 active Stakeholder to Facility users to proceed.',
  STAKEHOLDER_SURVEY:
    'Please, add at least 1 active Stakeholder to Facility users and create survey on Voting tab to proceed',
  SEND_TRIAL_TO_VENDOR_ERROR:
    'Please, wait for at least 1 answer to send trial request to Vendor.',
  JOIN_CONTRACT_PIPELINE:
    'If you join, you’ll participate in the RFP for this category',
  OUT_CONTRACT_PIPELINE:
    'If you opt out, you won’t participate in this contract pipeline',
  MARK_AS_READ: 'Mark as read',
  MARK_AS_UNREAD: 'Mark as unread',
  EXPORT_REJECTED_INVOICES:
    "The generated file will include a column labeled 'Rejection status' detailing the reason for each rejection."
}

export const MESSAGES = {}

export const TXT = {}

export const NOTIFICATIONS = {
  SURVEY_CREATED: 'Survey has been created.',
  GROUP_CHAT_UPDATED: 'Group chat has been updated.',
  COMMUNITY_SAVED: 'Community has been successfully saved.',
  GROUP_CHAT_CREATED: 'Group chat has been created.',
  PRIVATE_CHAT_CREATED: 'Private chat has been created.',
  OWNER_REASSIGNED: 'Owner has been successfully reassigned.',
  PRIVATE_CHAT_DELETED: 'Chat has been deleted.',
  GROUP_CHAT_DELETED: 'Group chat has been deleted.'
}

export const TimePeriod = [
  {
    value: 'year',
    label: 'Year',
    callback: () => moment().subtract(1, 'years')
  },
  {
    value: '6',
    label: '6 months',
    disabled: false,
    callback: () => moment().subtract(6, 'months')
  },
  {
    value: 'quarter',
    label: 'Quarter',
    disabled: false,
    callback: () => moment().subtract(3, 'months')
  }
]

export const ApprovePrsOptions = [
  {
    label:
      "I agree to help drive standardization, if applicable, for the product I'm requesting in order to help with safety, education, cost, supply chain goals, etc.",
    value: '1'
  },
  {
    label:
      'I agree that I am requesting the above product, and agree to formally present my request to the appropriate Value Analysis Committee and provide clinical evidence and other supporting material as to why/how this will improve outcomes and/or cost.',
    value: '2'
  },
  {
    label:
      'I agree that neither myself, nor any individual with whom I have a relationship has a conflict of interest with the company, vendor, distributor, or agent supplying this item.',
    value: '3'
  },
  {
    label:
      'I agree to assist in the monitoring of utilization and outcomes should the product/ equipment be implemented as part of the Value Analysis process.',
    value: '4'
  }
]
export const ERRORS_SIGN_IN = {
  SELF_SIGNUP_IS_IN_PROGRESS: 'self_signup_is_in_progress',
  WAITING_FOR_APPROVAL: 'waiting_for_approval'
}
