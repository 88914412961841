import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { Can } from 'features/ContractCategoryDetails/Providers/ContractCategoryDetailsAbilityProvider'
import AddResponsibleModal from './AddResponsibleModal'
import { useContractCategoryDetailsContext } from '../../../Providers/ContractCategoryDetailsContextProvider'
import { ACTIONS, SUBJECTS } from '../../../../Permission'
import { contractCategorySubject } from '../../../Abilities'
import { useContractCategoryDetailsPopup } from '../../../Providers/ContractCategoryDetailsPopupProvider'

const AddResponsibleBtn = () => {
  const ccDetails = useContractCategoryDetailsContext()
  const { details, isAllResponsibleUsersAdded } = ccDetails.state
  const { addResponsibleUserPopup } = useContractCategoryDetailsPopup()
  const { open } = addResponsibleUserPopup.actions

  return (
    <>
      {!isAllResponsibleUsersAdded && (
        <Can
          I={ACTIONS.EDIT}
          a={contractCategorySubject(SUBJECTS.CONTRACT_CATEGORY, details)}
        >
          <Button
            icon={<Plus16 />}
            type={BUTTON_TYPES.DEFAULT}
            onClick={open}
            upperCase
          >
            {BTN_TXT.ADD_TEAM_MEMBERS}
          </Button>
        </Can>
      )}
      <AddResponsibleModal />
    </>
  )
}

export default AddResponsibleBtn
