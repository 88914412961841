import { API_DICTIONARY, HTTP_METHODS } from '../../constants'
import Fetcher from '../../services/Fetcher'
import {
  TFacilities,
  TFinancialsListRequest,
  TFinancialsListResponse,
  TGetLinkPayment,
  TVendors
} from './types'

const fetcher = new Fetcher({})

export const getFinanceData: TFinancialsListRequest = async (data) =>
  fetcher.request<unknown, TFinancialsListResponse>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.FINANCES_DASHBOARD}${API_DICTIONARY.CALCULATIONS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getVendors = async () =>
  fetcher.request<unknown, TVendors>({
    url: `${API_DICTIONARY.VENDORS}${API_DICTIONARY.SHORT_INFO}`,
    method: HTTP_METHODS.GET
  })

export const getFacilities = async () =>
  fetcher.request<unknown, TFacilities>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.FINANCES_DASHBOARD}${API_DICTIONARY.FACILITIES}`,
    method: HTTP_METHODS.GET
  })

export const acceptAndPayReport = async (id: string) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.PAYMENTS_SESSION_LINK}${id}/`
  })

export const getLikToAccount = async (data: TGetLinkPayment) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.CONNECT_ACCOUNT_LINK}`,
    method: HTTP_METHODS.POST,
    data
  })

export const getBankDetails = async (data: TGetLinkPayment) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.CONNECT_ACCOUNT}`,
    method: HTTP_METHODS.POST,
    data
  })
