import FinancialsProvider from './Providers/FinancialsProvider'
import { FinancialsRouter } from './Router/Router'
import { FinancialsContainer } from './Container/Container'
import FinancialsAbilityProvider from './Providers/FinancialsAbilityProvider'

export const Financials = () => (
  <FinancialsAbilityProvider>
    <FinancialsProvider>
      <FinancialsContainer>
        <FinancialsRouter />
      </FinancialsContainer>
    </FinancialsProvider>
  </FinancialsAbilityProvider>
)
