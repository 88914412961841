import { API_DICTIONARY, HTTP_METHODS } from '../../constants'
import Fetcher from '../../services/Fetcher'
import { TVendors } from './types'

const fetcher = new Fetcher({})

export const getVendors = async () =>
  fetcher.request<unknown, TVendors>({
    url: `${API_DICTIONARY.VENDORS}${API_DICTIONARY.SHORT_INFO}`,
    method: HTTP_METHODS.GET
  })
