import { API_DICTIONARY, HTTP_METHODS } from '../../../../constants'
import Fetcher from '../../../../services/Fetcher'
import { DeleteHealthSystemRequest } from './types'

const fetcher = new Fetcher({})

export const deleteCCHS: DeleteHealthSystemRequest = async (
  id,
  data,
  successMessage
) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${id}/${API_DICTIONARY.HEALTH_SYSTEMS}${API_DICTIONARY.BULK_REMOVE}`,
    method: HTTP_METHODS.POST,
    successMessage,
    data
  })
