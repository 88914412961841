import { Can } from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT } from '../../../../constants'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { Popup } from 'components/Popup'
import { Form } from 'antd'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import Input from '../../../../components/Input/Input'
import { setLoading } from '../../../../redux/store/common/slice'
import { updatePRS } from '../../../../pages/ProductRequests/api'
import { notification } from '../../../../components/Notification'
import {
  PRS_STATUSES,
  PRS_STATUSES_MESSAGES
} from '../../../../pages/ProductRequests/constants'
import { useDispatch } from 'react-redux'
import { TDeclineValue } from '../../../Financials/types'

const DeclinePrs = () => {
  const prsDetails = usePRSDetailsContext()
  const dispatch = useDispatch()
  const { data } = prsDetails.state
  const { handleGetPRSData } = prsDetails.actions
  const { declinePopup } = usePRSDetailsPopupContext()
  const { state, actions } = declinePopup
  const openDeclinePopup = () => actions.open()
  const onCancelHandler = () => {
    declineForm.setFieldsValue({ decline_reason: '' })
    actions.close()
  }

  const [declineForm] = Form.useForm()

  const decline_reason = Form.useWatch('decline_reason', declineForm)

  const onDeclineSubmit = (value: TDeclineValue) => {
    dispatch(setLoading(true))
    updatePRS(data.uuid, { status: PRS_STATUSES.DECLINED, ...value })
      .then(() => {
        handleGetPRSData()
        notification.success({
          message: `${PRS_STATUSES_MESSAGES.declined}`
        })
        declineForm.setFieldsValue({ decline_reason: '' })
      })
      .catch((err) => {
        notification.error({
          message: err.data?.details[0]
        })
      })
      .finally(() => {
        dispatch(setLoading(false))
        onCancelHandler()
      })
  }
  return (
    <>
      <Can I={ACTIONS.DECLINE} a={subject(SUBJECTS.PRS, { ...data })}>
        <Button
          type={BUTTON_TYPES.DEFAULT}
          onClick={openDeclinePopup}
          danger
          upperCase
        >
          {BTN_TXT.DECLINE}
        </Button>
      </Can>
      <Popup
        visible={state.visible}
        onCancel={onCancelHandler}
        title="Decline the PRS request?"
        width={442}
        footer={
          <>
            <Button
              onClick={onCancelHandler}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              form="decline-contract-form"
              disabled={!decline_reason?.trim()}
              htmlType="submit"
              type={BUTTON_TYPES.PRIMARY}
              upperCase
              danger
            >
              {BTN_TXT.DECLINE}
            </Button>
          </>
        }
      >
        <Form
          id="decline-contract-form"
          form={declineForm}
          onFinish={onDeclineSubmit}
          layout="vertical"
        >
          <Typography.Headline6
            weight={TYPOGRAPHY_WEIGHT.NORMAL}
            className="financials-table__subtitle"
          >
            Please, provide a reason of the decline:
          </Typography.Headline6>
          <Input.TextArea
            propsItem={{
              label: 'Reason',
              name: 'decline_reason',
              rules: [
                {
                  max: 500
                }
              ]
            }}
            propsTextArea={{
              autoSize: {
                maxRows: 12
              },
              showCount: true,
              maxLength: 500
            }}
          />
        </Form>
      </Popup>
    </>
  )
}

export default DeclinePrs
