import { Sorter } from '../../../../components/Sorter/Sorter'
import { SortOrder } from 'antd/es/table/interface'
import { ColumnsType } from 'antd/es/table'
import { AddHealthSystemData } from './types'

export const columns: ColumnsType<AddHealthSystemData> = [
  {
    dataIndex: 'name',
    key: 'name',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<AddHealthSystemData>
        dataIndex="name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Health system
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  }
]
