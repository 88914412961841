import moment from 'moment'
import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import AmendmentCell from 'features/ContractDetails/HSList/HSTable/AmendmentCell/AmendmentCell'
import { CellWithSubtitle } from '../../../../components/CellWithSubtitle/CellWithSubtitle'
import { Sorter } from 'components/Sorter/Sorter'
import { Link } from '../../../../components/Link'
import { Tooltip } from '../../../../components/Tooltip/Tooltip'
import { AddLOPCell } from './AddLOPCell/AddLOPCell'
import { timelineDateFormat } from '../../../../components/Timelines/constants'
import { ReactComponent as WarningRed16 } from 'assets/svg/WarningRed16.svg'
import { IContractHealthSystem } from '../types'

const _getMyCommunityLink = (name: string, { uuid }: IContractHealthSystem) => (
  <CellWithSubtitle
    title={<Link to={`/my-community/health-system/${uuid}/`}>{name}</Link>}
  />
)

export const columnsShortView = (
  showMark: boolean
): ColumnsType<IContractHealthSystem> => [
  {
    dataIndex: 'not_completed',
    key: 'not_completed',
    width: 40,
    title: '',
    render: (_, { not_completed }) =>
      not_completed &&
      showMark && (
        <Tooltip title="To participate in the contract, please provide the effective date, and a letter of participation.">
          <WarningRed16 />
        </Tooltip>
      )
  },
  {
    dataIndex: 'name',
    key: 'name',
    width: 480,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<IContractHealthSystem>
        dataIndex="name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Health system
      </Sorter>
    ),
    render: _getMyCommunityLink,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'pricing_effective_date',
    key: 'pricing_effective_date',
    width: 280,
    title: 'Pricing effective date',
    render: (dateString) =>
      !!dateString ? moment(dateString).format(timelineDateFormat) : '-'
  },
  {
    dataIndex: 'amendment_document',
    key: 'amendment_document',
    width: 380,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<IContractHealthSystem>
        dataIndex="amendment_document"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        LoP / Amendment
      </Sorter>
    ),
    render: (_, { amendment_document }) => (
      <AmendmentCell amendment_document={amendment_document} />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'action',
    key: 'action',
    width: 64,
    render: (_, { uuid, not_completed }) =>
      not_completed && showMark && <AddLOPCell selectedHSRowKey={uuid} />
  }
]

export const onboardedHsColumns = (): ColumnsType<IContractHealthSystem> => [
  {
    dataIndex: 'name',
    key: 'name',
    width: 480,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<IContractHealthSystem>
        dataIndex="name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Health system
      </Sorter>
    ),
    render: _getMyCommunityLink,
    sorter: true,
    showSorterTooltip: false
  }
]
