import { Button, BUTTON_TYPES } from '../../../../../components/Button'
import { ACTIONS, contractSubject, SUBJECTS } from '../../../Abilities'
import { Can } from 'features/ContractDetails/Providers/ContractDetailsAbilityProvider'
import { useContractDetailsPopup } from '../../../Providers/ContractDetailsPopupProvider'
import { useContractDetailsContext } from '../../../Providers/ContractDetailsContextProvider'
import { BTN_TXT } from '../../../../../constants'
import { ReactComponent as More16 } from 'assets/svg/More16.svg'
import { AddLOPCellProps } from './types'

export const AddLOPCell = ({ selectedHSRowKey }: AddLOPCellProps) => {
  const contractDetailsContext = useContractDetailsContext()
  const { addAmendmentPopup } = useContractDetailsPopup()
  const actions = [
    {
      text: BTN_TXT.ADD_LOP_DOCUMENT,
      onClick: () => addAmendmentPopup.actions.open({ selectedHSRowKey })
    }
  ]

  return (
    <Can
      I={ACTIONS.SUBMIT}
      a={contractSubject(SUBJECTS.LOP, contractDetailsContext?.state?.details)}
    >
      <Button.Menu
        icon={<More16 />}
        type={BUTTON_TYPES.GHOST}
        options={actions}
        iconOnly
        hideArrow
        medium
      />
    </Can>
  )
}
