import { FinancialsChartLegendPart } from '../../../Financials/Rebates/FinancialsChartLegendPart/FinancialsChartLegendPart'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import {
  getOverallStatusLegendOrder,
  INITIAL_DATA_OVERALL_LEGEND,
  OVERALL_COLORS,
  OVERALL_STATUS_LABEL
} from './constants'
import { Chart } from './Chart'
import { useComplianceContext } from '../../../../pages/Compliance/Providers/ComplianceContextProvider'
import { useMemo } from 'react'

const OverallConversionCompliance = () => {
  const { overallCompliance } = useComplianceContext().state
  const total = useMemo(() => {
    return overallCompliance.find((chart) => chart.name === 'compliant')
      ?.percentage
  }, [overallCompliance])
  return (
    <div
      className="compliance-container__chart-wrapper part charts_ongoing-contracts_container"
      data-testid="overallConversionComplianceContainer"
    >
      <Typography.Body1
        className="compliance-container__chart-wrapper__title"
        weight={TYPOGRAPHY_WEIGHT.BOLD}
      >
        Overall conversion compliance
      </Typography.Body1>
      <div className="charts__description row align-center">
        <div className="round-chart__reports">
          <Chart
            total={total}
            data={getOverallStatusLegendOrder(
              !!overallCompliance?.length
                ? overallCompliance
                : INITIAL_DATA_OVERALL_LEGEND
            )}
          />
        </div>
        <div className="chart__legend">
          {(!!overallCompliance.length
            ? overallCompliance
            : INITIAL_DATA_OVERALL_LEGEND
          )?.map((i, idx) => {
            return (
              <div className="legend__part" key={idx}>
                <FinancialsChartLegendPart
                  color={OVERALL_COLORS[i.name]}
                  name={OVERALL_STATUS_LABEL[i.name]}
                  price={i.amount}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default OverallConversionCompliance
