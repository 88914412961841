import moment, { Moment } from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FinancialsChartLegendPart } from './FinancialsChartLegendPart/FinancialsChartLegendPart'
import { Datepicker } from '../../../components/Datepicker'
import { PaymentStatusChart } from './PaymentStatusChart/PaymentStatusChart'
import { Typography } from '../../../components/Typography'
import { getUser } from '../../../redux/store/user/getters'
import { setLoading } from '../../../redux/store/common/slice'
import { getPaymentStatusData } from './api'
import { getCompletedQuarter, getQuarterAndYear } from '../../../utils/moment'
import { RebatesTable } from './RebatesTable/RebatesTable'
import {
  getFinancialStatus,
  getFinancialStatusLegendOrder,
  getFinancialStatusLegend,
  REPORTS_COLORS
} from './constants'
import { timelineQuarterFormat } from '../../../components/Timelines/constants'
import { PaymentStatus } from './types'
import './styles.scss'

export const Rebates = () => {
  const [totalAmount, setTotalAmount] = useState(0)
  const [period, setPeriod] = useState<Moment>(getCompletedQuarter())
  const [reports, setReports] = useState<PaymentStatus[]>([])
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const isVendor = !!user.vendor

  useEffect(() => {
    if (!!period) {
      getChartsData()
    }
  }, [period])

  const getChartsData = async () => {
    try {
      dispatch(setLoading(true))
      const { data } = await getPaymentStatusData({
        params: getQuarterAndYear(period)
      })
      const legend = getFinancialStatusLegend(isVendor)
      const results: PaymentStatus[] = legend.map((i) => {
        const value = data.results.find((j) => j.status === i.status)
        const res: PaymentStatus = { ...i }
        if (value) {
          res.status = value.status
          res.percentage = `${value.percentage}`
          res.amount = +value.amount
        }
        return res
      })
      setReports(results)
      setTotalAmount(results.reduce((prev, cur) => prev + cur.amount, 0))
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <div className="rebates column gap-24">
      <div className="rebates__date-filter">
        <Datepicker
          propsDate={{
            picker: 'quarter',
            format: timelineQuarterFormat,
            value: period,
            onChange: (date) => setPeriod(moment(date).startOf('quarter'))
          }}
          propsItem={{
            label: 'Period'
          }}
        />
      </div>
      <div className="rebates__container column gap-24">
        <Typography.Headline4>Payment Status</Typography.Headline4>
        <div className="rebates__chart-container row">
          <div className="rebates__chart">
            <PaymentStatusChart
              isVendor={isVendor}
              total={totalAmount}
              data={getFinancialStatusLegendOrder(reports)}
            />
          </div>
          <div className="rebates__legend">
            {getFinancialStatusLegendOrder(reports).map((i, idx) => (
              <FinancialsChartLegendPart
                key={idx}
                color={REPORTS_COLORS[i.status]}
                name={getFinancialStatus(i.status, isVendor)}
                price={i.amount}
              />
            ))}
          </div>
        </div>
      </div>
      <RebatesTable period={period} />
    </div>
  )
}
