import { PaymentStatus } from './types'

export const REPORTS_COLORS = {
  sent: 'blue',
  disputed: 'light_red',
  waiting_for_response: 'blue',
  pending_for_payment: 'yellow',
  payment_in_progress: 'gray',
  paid: 'green',
  payment_failed: 'red',
  past_due: 'orange'
} as const

export const FINANCIAL_STATUS_FILTER = [
  {
    label: 'Paid',
    value: 'paid',
    index: 0
  },
  {
    label: 'Sent',
    value: 'sent',
    index: 1
  },
  {
    label: 'Waiting for response',
    value: 'waiting_for_response',
    index: 1
  },
  {
    label: 'Pending for payment',
    value: 'pending_for_payment',
    index: 2
  },
  {
    label: 'Payment in progress',
    value: 'payment_in_progress',
    index: 3
  },
  {
    label: 'Payment failed',
    value: 'payment_failed',
    index: 4
  },
  {
    label: 'Disputed',
    value: 'disputed',
    index: 5
  },
  {
    label: 'Past due',
    value: 'past_due',
    index: 6
  }
]

export const FINANCIAL_STATUS_LABEL = {
  sent: 'Sent',
  pending_for_payment: 'Pending for payment',
  disputed: 'Disputed',
  paid: 'Paid',
  payment_failed: 'Payment failed',
  payment_in_progress: 'Payment in progress',
  past_due: 'Past due',
  waiting_for_response: 'Waiting for response'
}

export const INITIAL_DATA_REPORTS_LEGEND = [
  {
    status: 'paid',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'sent',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'waiting_for_response',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'pending_for_payment',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'payment_in_progress',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'payment_failed',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'disputed',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'past_due',
    amount: 0,
    percentage: '0'
  }
]

export enum FINANCIAL_STATUS {
  PENDING_FOR_PAYMENT = 'pending_for_payment',
  PAYMENT_IN_PROGRESS = 'payment_in_progress',
  DECLINED = 'declined',
  PAID = 'paid',
  SENT = 'sent',
  PAYMENT_FAILED = 'payment_failed',
  PAST_DUE = 'past_due',
  DISPUTED = 'disputed',
  WAITING_FOR_RESPONSE = 'waiting_for_response '
}

export const getFinancialStatus = (status: string, isVendor: boolean) => {
  if (status === FINANCIAL_STATUS.SENT) {
    return isVendor
      ? FINANCIAL_STATUS_LABEL.waiting_for_response
      : FINANCIAL_STATUS_LABEL.sent
  } else {
    return FINANCIAL_STATUS_LABEL[status]
  }
}

export const getFinancialStatusLegendOrder = (data: PaymentStatus[]) =>
  data.sort((a, b) => {
    const indexA =
      FINANCIAL_STATUS_FILTER?.find((i) => a.status === i.value)?.index || 0
    const indexB =
      FINANCIAL_STATUS_FILTER?.find((i) => b.status === i.value)?.index || 0
    if (indexA < indexB) return -1
    else return 1
  })

export const getFinancialStatusLegend = (isVendor: boolean) =>
  INITIAL_DATA_REPORTS_LEGEND.filter(
    (i) => !i.status.includes(isVendor ? 'sent' : 'waiting_for_response')
  )
