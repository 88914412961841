import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import { Table } from '../../../../components/Table'
import { columns } from './constants'
import { isTableHasParams } from '../../../../helper/common'
import { setLoading } from '../../../../redux/store/common/slice'
import { getRebatesTableData } from './api'
import { getQuarterAndYear } from '../../../../utils/moment'
import { getUser } from '../../../../redux/store/user/getters'
import { useFinancialsAbility } from '../../Providers/FinancialsAbilityProvider'
import { DEFAULT_PAGE } from '../../../../components/Table/constants'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import {
  RebatesTableProps,
  TRebatesTableFilters,
  TRebatesTableFunc,
  TRebatesTableParams,
  TRebatesTableRequestData,
  TRebatesTableType
} from './types'
import './styles.scss'

export const RebatesTable = ({ period }: RebatesTableProps) => {
  const [tableData, setTableData] = useState<TRebatesTableType[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [sortParams, setSortParams] = useState<TRebatesTableRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [filters, setFilters] = useState<TRebatesTableFilters>({})
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })

  const tableDataTokenRef = useRef<CancelTokenSource>()

  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const ability = useFinancialsAbility()

  useEffect(() => {
    getTableData({ ...sortParams })
    setSelectedRowKeys([])
  }, [period])

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TRebatesTableRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TRebatesTableParams = {
      ...filters,
      ...getQuarterAndYear(period)
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getRebatesTableData({
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const handleSelectRow = (selectedRowKeys) =>
    setSelectedRowKeys(selectedRowKeys)

  const applyFilters: TRebatesTableFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    const newPageInfo = { ...pageInfo, pageNumber: 1 }
    setFilters(newFilters)
    setPageInfo(newPageInfo)
    getTableData({ ...sortParams, filters: newFilters, page: newPageInfo })
  }

  return (
    <>
      {firstLoad ? null : (
        <Table<TRebatesTableType>
          dataSource={tableData}
          columns={columns({
            applyFilters,
            filters,
            user
          })}
          className="financials-table"
          onChange={handleTableChange}
          hasSearchOrFilters={isTableHasParams<TRebatesTableFilters>(
            '',
            filters
          )}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          rowSelection={
            ability.can(ACTIONS.SELECT, SUBJECTS.REBATE)
              ? {
                  selectedRowKeys,
                  onChange: handleSelectRow
                }
              : undefined
          }
        />
      )}
    </>
  )
}
