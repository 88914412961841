import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setLoading } from 'redux/store/common/slice'
import useTabs, { TabsReturnActions, TabsReturnState } from 'hooks/useTabs'
import useRouter from '../../../hooks/useRouter'
import { getCCDetailsRequestAsync } from '../api'
import { contractCategorySubject } from '../Abilities'
import { getUser } from '../../../redux/store/user/getters'
import { useContractCategoryDetailsAbility } from './ContractCategoryDetailsAbilityProvider'
import { ROLES, SUBJECTS, ACTIONS } from '../../Permission'
import { CC_DETAILS_INITIAL } from './constants'
import {
  CONTRACT_CATEGORY_DETAILS_TABS,
  contractCategoryDetailsRoute
} from '../routes'
import { TContractCategoryDetails } from '../types'

type ContextProps = {
  state: {
    details: TContractCategoryDetails
    canEditContractCategory: boolean
    canManageHS: boolean
    isVendor: boolean
    isCommons: boolean
    isAllResponsibleUsersAdded: boolean
    isNewContractCategory: boolean
  } & TabsReturnState
  actions: {
    getCCDetailsAsync: () => Promise<void>
  } & TabsReturnActions
}

const ContractCategoryDetailsContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const createRoute = 'create'

const prepareRouteId = (routeId?: string) =>
  routeId ? (routeId === createRoute ? null : routeId) : null

const ContractCategoryDetailsContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const dispatch = useDispatch()
  const { id: routeId, tab } = useParams()
  const user = useSelector(getUser)
  const { query } = useRouter()

  const isVendor = !!user.vendor
  const isCommons = user?.role.includes('commons')

  const [id, setId] = useState<string | null>(prepareRouteId(routeId))
  const [details, setDetails] =
    useState<TContractCategoryDetails>(CC_DETAILS_INITIAL)

  const { state: tabsState, actions: tabsActions } = useTabs({
    tabs: CONTRACT_CATEGORY_DETAILS_TABS,
    activeTab: tab || CONTRACT_CATEGORY_DETAILS_TABS[0].key
  })

  const ability = useContractCategoryDetailsAbility()

  const canEditContractCategory = useMemo(
    () =>
      ability.can(
        ACTIONS.EDIT,
        contractCategorySubject(SUBJECTS.CONTRACT_CATEGORY, details)
      ),
    [ability, details]
  )
  const canManageHS = useMemo(
    () =>
      ability.can(
        ACTIONS.MANAGE,
        contractCategorySubject(SUBJECTS.HEALTH_SYSTEMS, details)
      ),
    [ability, details]
  )
  const isAllResponsibleUsersAdded = useMemo(
    () =>
      Boolean(
        details.community_responsibles.find((u) => u.role === ROLES.ANALYST) &&
          details.community_responsibles.find((u) => u.role === ROLES.LEGAL)
      ),
    [details.community_responsibles]
  )

  const isNewContractCategory = routeId === 'new'

  const getCCDetailsAsync = useCallback(async () => {
    if (!id) {
      throw new Error('Contract Category ID not provided')
    }

    if (id === 'new') return

    try {
      dispatch(setLoading(true))

      const response = await getCCDetailsRequestAsync(id)

      if (response?.data) {
        setDetails(response.data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, id])

  const _adjustTabsAccessibility = useCallback(() => {
    tabsActions.setTabs(
      CONTRACT_CATEGORY_DETAILS_TABS.map((i) => {
        //TODO: implemented in facilities users feature. please don't touch it (sprint 16) :)
        const inCreationDisabled =
          i.key === contractCategoryDetailsRoute.facilityUsers

        return {
          ...i,
          disabled: inCreationDisabled
        }
      })
    )
  }, [tabsActions, query, details])

  useLayoutEffect(() => {
    _adjustTabsAccessibility()
  }, [_adjustTabsAccessibility])

  useEffect(() => {
    if (!isNewContractCategory) {
      getCCDetailsAsync()
    }
  }, [isNewContractCategory, id])

  useEffect(() => {
    setId(prepareRouteId(routeId))
  }, [routeId])

  const context = useMemo(
    () => ({
      state: {
        details,
        canEditContractCategory,
        isAllResponsibleUsersAdded,
        isVendor,
        isNewContractCategory,
        isCommons,
        canManageHS,
        ...tabsState
      },
      actions: {
        ...tabsActions,
        getCCDetailsAsync
      }
    }),
    [
      details,
      tabsState,
      tabsActions,
      getCCDetailsAsync,
      canEditContractCategory,
      isVendor,
      isAllResponsibleUsersAdded,
      isNewContractCategory,
      isCommons,
      canManageHS
    ]
  )

  return (
    <ContractCategoryDetailsContext.Provider value={context}>
      {children}
    </ContractCategoryDetailsContext.Provider>
  )
}

export const useContractCategoryDetailsContext = () =>
  useContext(ContractCategoryDetailsContext)

export default ContractCategoryDetailsContextProvider
