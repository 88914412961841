import { ColumnsType } from 'antd/es/table'
import { Sorter } from '../../../components/Sorter/Sorter'
import { SortOrder } from 'antd/es/table/interface'
import { IChangedRows, TUncategorizedInvoiceWithKey } from './types'
import { DescriptionCell } from '../../../components/DescriptionCell/DescriptionCell'
import { CellWithSubtitle } from '../../../components/CellWithSubtitle/CellWithSubtitle'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { currencyParserInteger } from '../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { Input } from '../../../components/Input'
import { PLACEHOLDERS } from '../../../constants'
import { Select } from '../../../components/Select'
import { TOption } from '../../../components/Select/types'
import { Dispatch, SetStateAction } from 'react'
import './styles.scss'

type TUncategorizedColProps = {
  onViewDetailsPressed: (uuid: string) => void
  ccOptions: TOption[]
  setChangedRows: Dispatch<SetStateAction<IChangedRows>>
  changedRows: IChangedRows
}

export const columns = ({
  onViewDetailsPressed,
  ccOptions,
  setChangedRows,
  changedRows
}: TUncategorizedColProps): ColumnsType<TUncategorizedInvoiceWithKey> => [
  {
    dataIndex: 'item_description',
    key: 'item_description',
    width: 364,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TUncategorizedInvoiceWithKey>
        dataIndex="item_description"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Item Description
      </Sorter>
    ),
    render: (
      item_description: TUncategorizedInvoiceWithKey['item_description'],
      { uuid }
    ) => (
      <CellWithSubtitle
        title={item_description}
        subtitle={
          <Button
            type={BUTTON_TYPES.LINK}
            onClick={() => onViewDetailsPressed(uuid)}
          >
            View similar items
          </Button>
        }
      />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'supplier_item_number',
    key: 'supplier_item_number',
    width: 176,
    title: 'Supplier Item Number'
  },
  {
    dataIndex: 'contract_category',
    key: 'contract_category',
    width: 288,
    title: 'Contract Category',
    render: (
      contract_category: TUncategorizedInvoiceWithKey['contract_category'],
      { uuid }
    ) => (
      <Select.DefaultNoForm
        options={ccOptions}
        propsSelect={{
          getPopupContainer: (node) => document?.body ?? node,
          className: 'custom-edit-cells',
          value:
            changedRows[uuid]?.contract_category ??
            contract_category ??
            undefined,
          placeholder: PLACEHOLDERS.PLEASE_SELECT,
          onChange: (selectedOption) =>
            setChangedRows((prev) => ({
              ...prev,
              [uuid]: {
                ...prev[uuid],
                contract_category: selectedOption
              }
            }))
        }}
      />
    )
  },
  {
    dataIndex: 'unspsc_code',
    key: 'unspsc_code',
    width: 128,
    title: 'UNSPSC Code',
    render: (
      unspsc_code: TUncategorizedInvoiceWithKey['unspsc_code'],
      { uuid }
    ) => (
      <Input.NumberNoForm
        propsInputNumber={{
          min: '0',
          controls: false,
          parser: currencyParserInteger,
          className: 'custom-edit-cells',
          value:
            (Object.keys(changedRows[uuid] ?? {}).includes('unspsc_code') &&
            changedRows[uuid]?.unspsc_code !== undefined
              ? changedRows[uuid]?.unspsc_code
              : unspsc_code) ?? undefined,
          onChange: (value) =>
            setChangedRows((prev) => ({
              ...prev,
              [uuid]: {
                ...prev[uuid],
                unspsc_code: value?.toString() ?? null
              }
            })),
          placeholder: '0'
        }}
      />
    )
  },
  {
    dataIndex: 'hospitals',
    key: 'hospitals',
    width: 288,
    title: 'Hospitals',
    render: (hospitals: TUncategorizedInvoiceWithKey['hospitals']) => {
      const text = hospitals.join(', ')
      return <DescriptionCell content={text} text={text} />
    }
  }
]
