import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  TUpdateResponsibleUsersRequestData,
  TUpdateResponsibleUsersResponseData
} from '../RFP/RFPDetails/types'
import { NOTIFICATIONS } from '../../constants'
import { TCPRResponsiblesRequest } from './types'

const fetcher = new Fetcher({})

export const getRFPUsersForReassign = async (uuid) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.RFP}${uuid}/${API_DICTIONARY.OWNERS_REASSIGNMENT}`,
    method: HTTP_METHODS.GET
  })
export const getRFPUsersForMandatoryReassign = async (categories?: string[]) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.USERS}${API_DICTIONARY.AVAILABLE_OWNERS}`,
    method: HTTP_METHODS.GET,
    params: { categories, limit: 0, offset: 0 }
  })

export const getContractUsersForReassign = async (uuid) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.CONTRACTS}${uuid}/${API_DICTIONARY.OWNERS_REASSIGNMENT}`,
    method: HTTP_METHODS.GET
  })

export const reassignContractOwner = async (uuid: string, data: any) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.CONTRACTS}${uuid}/${API_DICTIONARY.OWNERS_REASSIGNMENT}`,
    method: HTTP_METHODS.POST,
    successMessage: NOTIFICATIONS.OWNER_REASSIGNED,
    data: { owner: data }
  })

export const reassignRFPOwner = async (uuid: string, data: any) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.RFP}${uuid}/${API_DICTIONARY.OWNERS_REASSIGNMENT}`,
    method: HTTP_METHODS.POST,
    successMessage: NOTIFICATIONS.OWNER_REASSIGNED,
    data: { owner: data }
  })

export const updateContractResponsibleUsersRequestAsync = async (
  id: string,
  data: TUpdateResponsibleUsersRequestData,
  successMessage
) =>
  fetcher.request<
    TUpdateResponsibleUsersRequestData,
    TUpdateResponsibleUsersResponseData
  >({
    url: `${API_DICTIONARY.CONTRACTS}${id}/${API_DICTIONARY.RESPONSIBLE_USERS}`,
    method: HTTP_METHODS.POST,
    successMessage,
    data
  })

export const getPRSUsersForReassign = async (uuid) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.PRS}${uuid}/${API_DICTIONARY.RESPONSIBLE_USER_REASSIGNMENT}`,
    method: HTTP_METHODS.GET
  })

export const reassignPRSOwner = async (uuid: string, data: any) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.PRS}${uuid}/${API_DICTIONARY.RESPONSIBLE_USER_REASSIGNMENT}`,
    method: HTTP_METHODS.POST,
    successMessage: NOTIFICATIONS.OWNER_REASSIGNED,
    data: { responsible_user: data }
  })

export const getCPRUsersForReassign: TCPRResponsiblesRequest = async (data) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.PIPELINES}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.RESPONSIBLES}`,
    method: HTTP_METHODS.GET,
    ...data
  })
export const getContractCategoryUsersForReassign = async (uuid: string) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${uuid}/${API_DICTIONARY.OPTIONS}${API_DICTIONARY.OWNER}`,
    method: HTTP_METHODS.GET
  })
export const reassignCPRResponsible = async (uuid: string, data: string) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.PIPELINES}${uuid}/${API_DICTIONARY.RESPONSIBLE}`,
    method: HTTP_METHODS.PUT,
    data: { responsible: data }
  })

export const reassignContractCategoryResponsible = async (
  uuid: string,
  data: string
) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${uuid}/${API_DICTIONARY.OWNER}`,
    method: HTTP_METHODS.POST,
    data: { owner: data }
  })
