import {
  Ability,
  AbilityBuilder,
  ForcedSubject,
  MongoQuery,
  subject
} from '@casl/ability'
import { ACTIONS, SUBJECTS } from 'features/Permission/constants'
import { TContractCategoryDetails } from 'features/ContractCategoryDetails/types'

export type Actions = ValueOfObject<typeof ACTIONS>
export type Subjects = ValueOfObject<typeof SUBJECTS>

export type PossibleAbilities = [
  Actions,
  Subjects | (TContractCategoryDetails & ForcedSubject<Subjects>)
]

export type Conditions =
  | Record<PropertyKey, number | string | MongoQuery>
  | MongoQuery<Record<PropertyKey, number | string | MongoQuery>>

export type ContractCategoryAbility = Ability<PossibleAbilities, Conditions>

export type ContractCategoryAbilityBuilder =
  AbilityBuilder<ContractCategoryAbility>

export const contractCategorySubject = (type, object) =>
  subject<Subjects, TContractCategoryDetails>(type, { ...object })
