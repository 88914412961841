import { useDispatch } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import {
  TSetupEditContractCategoryErrorType,
  TSetupEditContractCategoryFormType
} from '../General/EditContractCategory/types'
import { TOption } from '../../../components/Select/types'
import { useContractCategoryDetailsContext } from '../Providers/ContractCategoryDetailsContextProvider'
import { Form } from 'antd'
import { setLoading } from '../../../redux/store/common/slice'
import {
  editCCDetailsRequestAsync,
  getContractCategoriesOptions,
  getLedByOptions,
  setupCCDetailsRequestAsync
} from '../api'
import { AxiosResponse } from 'axios'
import useRouter from '../../../hooks/useRouter'
import { useNavigate } from 'react-router'
import { routes } from '../../../router'

export const useSetupEditContractCategory = (isNew: boolean) => {
  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState(false)
  const [errors, setErrors] = useState<TSetupEditContractCategoryErrorType>({})
  const [predefinedCCValue, setPredefinedCCValue] = useState('')
  const [contractCategories, setContractCategories] = useState<TOption[]>([])
  const [ledByOptions, setLedByOptions] = useState<TOption[]>([])

  const [form] = Form.useForm<TSetupEditContractCategoryFormType>()

  const {
    query: { contract_category }
  } = useRouter()
  const navigate = useNavigate()
  const router = useRouter()

  const {
    state: {
      details: {
        expected_spend,
        expected_savings,
        compliance_requirement,
        uuid
      }
    },
    actions
  } = useContractCategoryDetailsContext()

  useEffect(() => {
    if (contract_category && !!contractCategories.length) {
      if (contractCategories.find((cc) => cc.value === contract_category)) {
        form.setFieldsValue({
          contract_category
        })
        setPredefinedCCValue(contract_category)
      }
      navigate(location.pathname, { replace: true })
    }
  }, [contract_category, contractCategories])

  useEffect(() => {
    if (isNew) {
      getContractCategoriesOptions({}).then((results) => {
        setContractCategories(
          results?.data.results.map((r) => {
            return {
              label: r.name,
              value: r.uuid
            }
          })
        )
      })
      getLedByOptions({}).then((results) => {
        setLedByOptions(
          results?.data.results.map((r) => {
            return {
              label: r.label,
              value: r.value
            }
          })
        )
      })
    }
  }, [isNew])

  const resetFormValues = useCallback(() => {
    if (!isNew) {
      form.setFieldsValue({
        expected_spend: expected_spend,
        expected_savings: expected_savings,
        compliance_requirement: compliance_requirement ?? ''
      })
    } else {
      const fieldsToReset = [
        'expected_spend',
        'expected_savings',
        'compliance_requirement',
        'led_by'
      ]

      if (predefinedCCValue) {
        form.setFieldsValue({
          contract_category: predefinedCCValue
        })
      } else {
        fieldsToReset.push('contract_category')
      }
      form.resetFields(fieldsToReset)
    }
    setErrors({})
  }, [
    expected_spend,
    expected_savings,
    compliance_requirement,
    errors,
    setErrors,
    form,
    predefinedCCValue
  ])

  useEffect(() => {
    resetFormValues()
  }, [expected_spend, expected_savings, compliance_requirement, isNew])

  const onSubmit = useCallback(
    async (values: TSetupEditContractCategoryFormType) => {
      dispatch(setLoading(true))
      let newValues = {
        expected_savings: values.expected_savings,
        expected_spend: values.expected_spend
      } as TSetupEditContractCategoryFormType
      if (values.compliance_requirement)
        newValues.compliance_requirement = values.compliance_requirement
      if (isNew) {
        newValues = {
          ...newValues,
          led_by: values.led_by,
          contract_category: values.contract_category
        }
      }
      try {
        setIsActive(false)
        if (isNew) {
          const result = await setupCCDetailsRequestAsync(newValues)
          if (result.data?.uuid) {
            router.push(
              `${routes.contracts}${routes.contractCategories}/${result.data?.uuid}`,
              { replace: true }
            )
          }
        } else {
          await editCCDetailsRequestAsync(uuid, newValues)
          actions.getCCDetailsAsync()
          setErrors({})
        }
      } catch (error) {
        const typedResponse = error as AxiosResponse
        setErrors(typedResponse.data)
        setIsActive(true)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [dispatch, editCCDetailsRequestAsync, form, isNew, isActive, setIsActive]
  )

  return {
    onSubmit,
    form,
    errors,
    isActive,
    setIsActive,
    resetFormValues,
    ledByOptions,
    contractCategories
  }
}
