import { useMemo } from 'react'
import './styles.scss'
import { EditContractCategory } from './EditContractCategory/EditContractCategory'
import { AdditionalInfo } from './ViewContractCategory/AdditionalInfo'
import { ViewContractCategory } from './ViewContractCategory/ViewContractCategory'
import { useContractCategoryDetailsContext } from '../Providers/ContractCategoryDetailsContextProvider'
import ResponsibleUsers from './ResponsibleUsers/ResponsibleUsers'

export const General = () => {
  const { canEditContractCategory, isNewContractCategory } =
    useContractCategoryDetailsContext().state

  const ContractCategoryDetails = useMemo(
    () =>
      canEditContractCategory || isNewContractCategory ? (
        <EditContractCategory isNew={isNewContractCategory} />
      ) : (
        <ViewContractCategory />
      ),
    [canEditContractCategory, isNewContractCategory]
  )
  return (
    <div className="contract-category-details-general">
      <div className="contract-category-details-general__details row gap-24">
        {ContractCategoryDetails}
        {!isNewContractCategory && <AdditionalInfo />}
      </div>
      {!isNewContractCategory && (
        <div className="contract-category-details-general__responsible">
          <ResponsibleUsers />
        </div>
      )}
    </div>
  )
}
