import {
  FC,
  createContext,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import usePopup, {
  PopupReturnType,
  PopupReturnTypeWithPayload
} from 'hooks/usePopup'
import { DeleteResponseUserPayload } from 'features/ContractDetails/ContractDetailsGeneral/ResponsibleUsers/DeleteUserPopup/types'

type ContextProps = {
  deleteResponsibleUserPopup: PopupReturnTypeWithPayload<DeleteResponseUserPayload>
  addResponsibleUserPopup: PopupReturnType
  addHealthSystemPopup: PopupReturnType
  deleteHealthSystemsPopup: PopupReturnType
}

const basePopup = (title: string) => ({
  title
})

const ContractCategoryDetailsPopupContext = createContext<ContextProps>({
  deleteResponsibleUserPopup: null!,
  addResponsibleUserPopup: null!,
  addHealthSystemPopup: null!,
  deleteHealthSystemsPopup: null!
})

const ContractCategoryDetailsPopupProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const deleteResponsibleUserPopupProps = usePopup<DeleteResponseUserPayload>(
    basePopup('Delete team member?')
  )
  const addResponsibleUserPopupProps = usePopup(
    basePopup('Add team member to the contract category')
  )
  const addHealthSystemPopupProps = usePopup(basePopup('Add Health System'))
  const deleteHealthSystemsPopupProps = usePopup(
    basePopup('Delete Health System')
  )
  const context = useMemo(
    () => ({
      deleteResponsibleUserPopup: deleteResponsibleUserPopupProps,
      addResponsibleUserPopup: addResponsibleUserPopupProps,
      addHealthSystemPopup: addHealthSystemPopupProps,
      deleteHealthSystemsPopup: deleteHealthSystemsPopupProps
    }),
    [
      deleteResponsibleUserPopupProps,
      addResponsibleUserPopupProps,
      addHealthSystemPopupProps,
      deleteHealthSystemsPopupProps
    ]
  )

  return (
    <ContractCategoryDetailsPopupContext.Provider value={context}>
      {children}
    </ContractCategoryDetailsPopupContext.Provider>
  )
}

export const useContractCategoryDetailsPopup = () =>
  useContext(ContractCategoryDetailsPopupContext)

export default ContractCategoryDetailsPopupProvider
