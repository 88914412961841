import Fetcher from '../../../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../../../constants'
import {
  AddHealthSystemRequest,
  AddHealthSystemResponse,
  AddHSToCCRequest
} from './types'

const fetcher = new Fetcher({})

export const getHSList: AddHealthSystemRequest = async (id, data) =>
  fetcher.request<unknown, AddHealthSystemResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${id}/${API_DICTIONARY.HEALTH_SYSTEMS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.HEALTH_SYSTEM}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const addHSToCC: AddHSToCCRequest = (id, data, successMessage) =>
  fetcher.request<unknown, AddHealthSystemResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${id}/${API_DICTIONARY.HEALTH_SYSTEMS}`,
    method: HTTP_METHODS.POST,
    data,
    successMessage
  })
