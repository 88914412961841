export const FINANCIAL_TABS = [
  {
    key: '0',
    label: 'Rebates'
  },
  {
    key: '1',
    label: 'Admin'
  },
  {
    key: '2',
    label: 'Cognus AR'
  },
  {
    key: '3',
    label: 'Payables'
  },
  {
    key: '4',
    label: 'All'
  }
]
