import { Route, Routes } from 'react-router-dom'
import { contractPageRoute } from './constants'
import { CognusRepository } from '../../features/CognusRepository/CognusRepository'
import { OpportunityAnalysisCommons } from '../../features/OportunityAnalysisCommons/OpportunityAnalysisCommons'
import { ContractPipeline } from '../../features/ContractPipeline/ContractPipeline'
import ContractsPage from '../../features/Contracts/ContractsPage'

const ContractPageRouter = () => (
  <Routes>
    <Route path={contractPageRoute.contracts} element={<ContractsPage />} />
    <Route
      path={contractPageRoute.cognusRepository}
      element={<CognusRepository />}
    />
    <Route
      path={contractPageRoute.contractPipeline}
      element={<ContractPipeline />}
    />
    <Route
      path={contractPageRoute.opportunityAnalysis}
      element={<OpportunityAnalysisCommons />}
    />
    <Route index element={<ContractsPage />} />
  </Routes>
)

export default ContractPageRouter
