import { useCallback, useMemo } from 'react'
import { Form } from 'antd'
import moment, { Moment } from 'moment'
import './styles.scss'
import { BTN_TXT, LABELS } from 'constants/txt'
import { validateFileTypes } from 'helper/common'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { FileUpload } from 'components/FileUpload/FileUpload'
import { Attachment } from 'components/Attachment/Attachment'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import {
  ACCEPT_DOCUMENT_TYPES,
  UPLOAD_DOCUMENTS_CONFIG
} from 'features/ContractDetails/ExtendContract/constants'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { Props } from './types'
import { useHSTableContext } from 'features/ContractDetails/HSList/Providers/HSTableProvider'
import { timelineDateFormat } from 'components/Timelines/constants'
import { Datepicker } from 'components/Datepicker'
import { convertDateToRequestFormat } from 'utils/moment'

export const AddAmendment = (props: Props) => {
  const {
    amendmentDocument,
    setAmendmentDocument,
    pricingEffectiveDate,
    setPricingEffectiveDate
  } = props

  const contractDetailsContext = useContractDetailsContext()
  const { addAmendmentPopup } = useContractDetailsPopup()

  const { refreshTableData } = useHSTableContext()
  const { addAmendmentDocumentAsync } = contractDetailsContext.actions
  const { uploadHSAmendmentDocumentAsync } = contractDetailsContext.actions
  const { selectedHSRowKey } = addAmendmentPopup.state.payload

  const handleUploadDocument = useCallback(
    (file: File[]) =>
      new Promise((res) => {
        setAmendmentDocument({
          file: file[0],
          uploadedAt: moment()
        })

        res(null)
      }),
    [setAmendmentDocument]
  )

  const handleDeleteDocument = useCallback(() => {
    setAmendmentDocument(null)
  }, [setAmendmentDocument])

  const checkDateRange = useCallback(
    (date: Moment): boolean => {
      const startDate = moment(contractDetailsContext.state.details.start_date)
      const endDate = moment(contractDetailsContext.state.details.finish_date)
      return !date.isBetween(startDate, endDate, 'days', '[]')
    },
    [
      contractDetailsContext.state.details.finish_date,
      contractDetailsContext.state.details.start_date
    ]
  )

  const closeAmendmentPopup = useCallback(() => {
    addAmendmentPopup.actions.close()
    setAmendmentDocument(null)
    setPricingEffectiveDate(null)
  }, [addAmendmentPopup.actions, setAmendmentDocument, setPricingEffectiveDate])

  const handleFinishForm = useCallback(async () => {
    if (amendmentDocument === null) {
      return
    }

    const hsAmendmentFile = await uploadHSAmendmentDocumentAsync(
      amendmentDocument.file
    )

    if (hsAmendmentFile?.uuid && selectedHSRowKey && pricingEffectiveDate) {
      await addAmendmentDocumentAsync(selectedHSRowKey.toString(), {
        priceEffectiveDate: convertDateToRequestFormat(pricingEffectiveDate),
        file: hsAmendmentFile
      })
      await refreshTableData()
      closeAmendmentPopup()
    }
  }, [
    closeAmendmentPopup,
    amendmentDocument,
    selectedHSRowKey,
    refreshTableData,
    pricingEffectiveDate,
    uploadHSAmendmentDocumentAsync
  ])

  const UploadDocumentField = useMemo(
    () =>
      amendmentDocument ? (
        <Attachment
          className="add-amendment-form__attachment"
          link={URL.createObjectURL(amendmentDocument.file)}
          filename={amendmentDocument.file.name}
          onDelete={handleDeleteDocument}
        />
      ) : (
        <FileUpload
          documentType="attachments"
          uploadBtnText={BTN_TXT.UPLOAD_LOP}
          handleUploadFile={handleUploadDocument}
          uploadFilesProps={{
            ...UPLOAD_DOCUMENTS_CONFIG,
            onDropAccepted: handleUploadDocument,
            validator: (file) => validateFileTypes(ACCEPT_DOCUMENT_TYPES, file)
          }}
        />
      ),
    [amendmentDocument, handleDeleteDocument, handleUploadDocument]
  )

  return (
    <Form onFinish={handleFinishForm} id="add-amendment-form-id">
      <Typography.Body1>
        To add Health System to the contract, please upload LOP and provide
        pricing effective date.
      </Typography.Body1>
      <div className="row mt-24 two-columns">
        <Datepicker
          propsItem={{
            name: 'pricing_effective_date',
            label: LABELS.PRICING_EFFECTIVE_DATE
          }}
          propsDate={{
            // this required for popup render outside the modal window
            // do not remove this line of code
            getPopupContainer: (node) => document?.body ?? node,
            // ---
            disabledDate: checkDateRange,
            format: timelineDateFormat,
            placeholder: 'mm/dd/yyyy',
            value: pricingEffectiveDate,
            name: 'pricing_effective_date',
            onChange: setPricingEffectiveDate
          }}
        />
      </div>
      <div className="row mt-24 vertical">
        <Typography.Label
          className="add-amendment-form__lop-label"
          weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
        >
          Letter of Participation
        </Typography.Label>
        {UploadDocumentField}
      </div>
    </Form>
  )
}
