import { useMemo } from 'react'
import './styles.scss'
import { useContractCategoryDetailsContext } from '../../../Providers/ContractCategoryDetailsContextProvider'
import { UserListItem } from './UserListItem'
import { IPartialUserInfo } from '../../../../../redux/store/user/types'

const UserList = () => {
  const ccDetailsContext = useContractCategoryDetailsContext()

  const { details } = ccDetailsContext.state

  const responsibleUsers = useMemo(() => {
    const resonsibles = [] as IPartialUserInfo[]
    if (!!details?.owner?.uuid) {
      resonsibles.push(details.owner)
    }

    return [...resonsibles, ...(details?.community_responsibles ?? [])]
  }, [details?.community_responsibles, details?.owner])

  return (
    <div className="rfp-details-responsible__user-list column mt-8 full-width">
      {responsibleUsers.map((user) => (
        <UserListItem key={user.uuid} user={user} />
      ))}
    </div>
  )
}

export default UserList
