import { Menu } from '../../../components/Menu/Menu'
import { Container } from '../../../components/Container/Container'
import useRouter from '../../../hooks/useRouter'
import { useFinancialsContext } from '../Providers/FinancialsProvider'

export const FinancialsContainer = ({ children }) => {
  const router = useRouter()
  const invoiceDataContext = useFinancialsContext()
  const { tabs, activeTab } = invoiceDataContext.state
  const { setActiveTab } = invoiceDataContext.actions

  return (
    <Container
      className="financials-container"
      title="Financials"
      menu={
        <Menu
          mode="horizontal"
          onSelect={(e) => {
            setActiveTab(e.key)
            router.push(e.key)
          }}
          selectedKeys={[activeTab]}
          defaultSelectedKeys={[activeTab]}
          items={tabs}
        />
      }
    >
      {children}
    </Container>
  )
}
