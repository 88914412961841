import { FC, useState } from 'react'
import './styles.scss'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import { ROLES_NAMES } from 'constants/common'
import { Avatar } from 'components/Avatar/Avatar'
import { ACTIONS, ROLES, SUBJECTS } from 'features/Permission'
import { Props } from './types'
import { useContractCategoryDetailsAbility } from '../../../Providers/ContractCategoryDetailsAbilityProvider'
import { useContractCategoryDetailsContext } from '../../../Providers/ContractCategoryDetailsContextProvider'
import { contractCategorySubject } from '../../../Abilities'
import { useContractCategoryDetailsPopup } from '../../../Providers/ContractCategoryDetailsPopupProvider'
import { Reassign } from '../../../../Reassign/ReassignPopup'
import { ReactComponent as ReassignIcon } from 'assets/svg/Reassign.svg'

export const UserListItem: FC<Props> = (props) => {
  const { user } = props

  const ability = useContractCategoryDetailsAbility()
  const ccDetailsContext = useContractCategoryDetailsContext()
  const { deleteResponsibleUserPopup } = useContractCategoryDetailsPopup()
  const [isOpenReassign, setIsOpenReassign] = useState(false)

  const { details, isCommons } = ccDetailsContext.state
  const { getCCDetailsAsync } = ccDetailsContext.actions
  const { open } = deleteResponsibleUserPopup.actions

  const name = user.first_name + ' ' + user.last_name
  const capitalCharName = `${user.first_name?.charAt(0) || ''}${
    user.last_name?.charAt(0) || ''
  }`.toUpperCase()

  const onDelete = () => {
    if (user) {
      open({ user })
    }
  }

  const canDeleteResponsibleUser =
    ability.can(
      ACTIONS.DELETE,
      contractCategorySubject(SUBJECTS.RESPONSIBLE_USER, details)
    ) &&
    (user.role === ROLES.ANALYST || user.role === ROLES.LEGAL)

  const canReassignOwner =
    ability.can(
      ACTIONS.EDIT,
      contractCategorySubject(SUBJECTS.OWNER, details)
    ) &&
    user.role !== ROLES.ANALYST &&
    user.role !== ROLES.LEGAL

  return (
    <>
      <div className="rfp-details-responsible-user row space-between full-width">
        <div className="rfp-details-responsible-user__user row align-center">
          <div className="rfp-details-responsible-user__avatar row align-center">
            <Avatar
              name={capitalCharName}
              alt="Responsible user image"
              src={user.avatar?.file_preview}
            />
          </div>
          <div className="rfp-details-responsible-user__content column space-between">
            <Typography.Body1 weight="semi-bold">{name}</Typography.Body1>
            <Typography.Caption className="rfp-details-responsible-user__role">
              {ROLES_NAMES[user.role]}
            </Typography.Caption>
          </div>
        </div>
        <div className="rfp-details-responsible-user__actions">
          {canDeleteResponsibleUser ? (
            <Button.Icon
              type={BUTTON_TYPES.LINK}
              icon={<Delete16 />}
              onClick={onDelete}
              small
              danger
            />
          ) : (
            canReassignOwner && (
              <Button.Icon
                type={BUTTON_TYPES.LINK}
                icon={<ReassignIcon />}
                onClick={() => setIsOpenReassign(true)}
                small
              />
            )
          )}
        </div>
        {canReassignOwner && (
          <Reassign
            isRFP={false}
            isContractCategory={true}
            isReassignPopupOpen={isOpenReassign}
            setIsReassignPopupOpen={setIsOpenReassign}
            ownerRole={user.role}
            ownerName={name}
            uuid={details?.uuid}
            isCommons={isCommons}
            update={getCCDetailsAsync}
          />
        )}
      </div>
    </>
  )
}
