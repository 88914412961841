import 'features/ContractDetails/ContractDetailsGeneral/ResponsibleUsers/styles.scss'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import ContractCategoryResponsibleContextProvider from '../../Providers/ContractCategoryResponsibleContextProvider'
import AddResponsibleBtn from './AddResponsibles/AddResponsibleBtn'
import { DeleteResponsibleUserPopup } from './DeleteUserPopup/Popup'
import UserList from './UserList/UserList'

const ResponsibleUsers = () => {
  return (
    <ContractCategoryResponsibleContextProvider>
      <div className="contract-category-details-responsible">
        <FieldWithLabel title="Team Responsible">
          <UserList />
        </FieldWithLabel>
        <div className="contract-details-general__add-responsible">
          <AddResponsibleBtn />
        </div>
      </div>
      <DeleteResponsibleUserPopup />
    </ContractCategoryResponsibleContextProvider>
  )
}

export default ResponsibleUsers
