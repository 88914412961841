import {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useContext
} from 'react'
import process from 'process'
import branch from 'branch-sdk'

type BranchContext = {}

const BranchIOContext = createContext<BranchContext>({})

const BranchIOProvider: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    if (process.env.REACT_APP_BRANCH_KEY) {
      branch.init(process.env.REACT_APP_BRANCH_KEY, {}, (_, data) => {
        if (data?.data_parsed) {
          Object.keys(data.data_parsed).forEach((key) => {
            if (
              key === '$deeplink_path' &&
              typeof data.data_parsed[key] === 'string'
            ) {
              window.location.replace(
                `${window.location.origin}${data.data_parsed[key]}`
              )
            }
          })
        }
      })
    }
  }, [])

  return (
    <BranchIOContext.Provider value={{}}>{children}</BranchIOContext.Provider>
  )
}

export const useAppSocket = () => useContext(BranchIOContext)

export default BranchIOProvider
