import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'

import { IUser } from 'redux/store/user/types'
import { ROLES } from 'features/Permission'
import {
  Conditions,
  PRSItemAbility,
  PRSItemAbilityBuilder,
  PossibleAbilities
} from 'features/PRS/Abilities/types'
import { ACTIONS, SUBJECTS } from 'features/PRS/Abilities/constants'
import {
  PRS_STATUSES,
  RCA_DECISION
} from '../../../pages/ProductRequests/constants'
import {
  isClinicalSponsor,
  isCommunityResponsible,
  isInStatuses,
  isRcaResponsible,
  isInTrialStatuses,
  RcaStatusUnion
} from './utils'

const PRSItemAbilityClass = Ability as AbilityClass<PRSItemAbility>

export const ability = new Ability<PossibleAbilities, Conditions>()

export default (user: IUser) => {
  const builder = new AbilityBuilder(PRSItemAbilityClass)

  defineAnonymousAbilities(builder)

  switch (user.role) {
    case ROLES.COMMUNITY_PRESIDENT:
      defineCPAbilities(builder)
      break
    case ROLES.STAKEHOLDER:
      defineStakeholderAbilities(builder, user)
      break
    case ROLES.COMMUNITY_RCA:
      defineRCAAbilities(builder, user)
      break
    case ROLES.VENDOR_CONTRACT_STEWARD:
      defineVCSAbilities(builder)
      break
    case ROLES.VENDOR_CONTRACT_ADMIN:
      defineVCAAbilities(builder)
      break
    case ROLES.VICE_PRESIDENT:
    case ROLES.CONTRACT_STEWARD:
      defineCommunityResponsibleAbilities(builder, user)
      break
    case ROLES.LEGAL:
    case ROLES.ANALYST:
    case ROLES.VENDOR_LEGAL:
    case ROLES.INFLUENCER:
    case ROLES.HOSPITAL_ADMIN:
    case ROLES.VENDOR_ANALYST:
      defineVCVAbilities(builder, user)
      break
  }

  return new Ability(builder.rules) as PRSItemAbility
}

const defineVCVAbilities = (builder: PRSItemAbilityBuilder, user) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.PRS)
  if (
    user.role === ROLES.ANALYST ||
    user.role === ROLES.INFLUENCER ||
    user.role === ROLES.HOSPITAL_ADMIN ||
    user.role === ROLES.LEGAL
  ) {
    builder.can(ACTIONS.VIEW, SUBJECTS.CLINICAL_RATIONALE, {
      ...isInStatuses([
        PRS_STATUSES.IN_REVIEW,
        PRS_STATUSES.REVENUE_ASSESSMENT,
        PRS_STATUSES.DECLINED,
        PRS_STATUSES.DECISION_MAKING,
        PRS_STATUSES.CANCELED,
        PRS_STATUSES.SURVEY_CREATION,
        PRS_STATUSES.VOTING_IN_PROGRESS,
        PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
        PRS_STATUSES.ARCHIVED
      ])
    })
  }
  builder.can(ACTIONS.VIEW, SUBJECTS.RESULTS_TAB, {
    ...isInStatuses([PRS_STATUSES.ARCHIVED])
  })
  if (user.role === ROLES.VENDOR_LEGAL || user.role === ROLES.VENDOR_ANALYST) {
    builder.can(ACTIONS.VIEW, SUBJECTS.TRIAL_REQUESTED_NOTE, {
      ...isInStatuses([
        PRS_STATUSES.IN_REVIEW,
        PRS_STATUSES.REVENUE_ASSESSMENT
      ]),
      ...isInTrialStatuses([PRS_STATUSES.TRIAL_REQUESTED])
    })
  }
}
const defineStakeholderAbilities = (builder: PRSItemAbilityBuilder, user) => {
  builder.can(ACTIONS.DECLINE, SUBJECTS.PRS, {
    ...isClinicalSponsor(user),
    ...isInStatuses([PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL])
  })
  builder.can(ACTIONS.APPROVE, SUBJECTS.REQUEST, {
    ...isClinicalSponsor(user),
    ...isInStatuses([
      PRS_STATUSES.CREATED,
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.RCA_REVIEW,
      PRS_STATUSES.SURVEY_CREATION,
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.DECISION_MAKING,
      PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL
    ])
  })
  builder.can(ACTIONS.APPROVE, SUBJECTS.PRS_FROM_CLINICAL_SPONSOR, {
    ...isClinicalSponsor(user),
    ...isInStatuses([PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL])
  })
  builder.can(ACTIONS.SEE, SUBJECTS.LABEL, {
    ...isClinicalSponsor(user),
    ...isInStatuses([PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL])
  })
  builder.can(ACTIONS.SEE, SUBJECTS.CS_APPROVAL_LABEL, {
    ...isClinicalSponsor(user),
    ...isInStatuses([PRS_STATUSES.IN_REVIEW])
  })
  builder.can(ACTIONS.OPEN, SUBJECTS.VOTING, {
    ...isInStatuses([
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.DECISION_MAKING
    ])
  })
  builder.can(ACTIONS.OPEN, SUBJECTS.CLINICAL_RATIONALE, {
    ...isClinicalSponsor(user),
    ...isInStatuses([PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.FACILITY_USERS, {
    ...isInStatuses([
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.SURVEY_CREATION,
      PRS_STATUSES.WAITING_FOR_REVIEW,
      PRS_STATUSES.RCA_REVIEW,
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
      PRS_STATUSES.CANCELED,
      PRS_STATUSES.DECLINED,
      PRS_STATUSES.ARCHIVED,
      PRS_STATUSES.CREATED,
      PRS_STATUSES.DECISION_MAKING,
      PRS_STATUSES.REVENUE_ASSESSMENT
    ])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.CLINICAL_RATIONALE, {
    ...isInStatuses([
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.REVENUE_ASSESSMENT,
      PRS_STATUSES.DECLINED,
      PRS_STATUSES.DECISION_MAKING,
      PRS_STATUSES.CANCELED,
      PRS_STATUSES.SURVEY_CREATION,
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
      PRS_STATUSES.ARCHIVED
    ])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.PRE_APPROVAL_LABEL, {
    ...isInStatuses([PRS_STATUSES.IN_REVIEW, PRS_STATUSES.REVENUE_ASSESSMENT]),
    ...isInTrialStatuses([PRS_STATUSES.TRIAL_PRE_APPROVAL])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.FACILITY_USERS, {
    ...isInStatuses([
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.SURVEY_CREATION,
      PRS_STATUSES.WAITING_FOR_REVIEW,
      PRS_STATUSES.RCA_REVIEW,
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
      PRS_STATUSES.CANCELED,
      PRS_STATUSES.DECLINED,
      PRS_STATUSES.ARCHIVED,
      PRS_STATUSES.CREATED,
      PRS_STATUSES.DECISION_MAKING,
      PRS_STATUSES.REVENUE_ASSESSMENT
    ])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.RESULTS_TAB, {
    ...isInStatuses([PRS_STATUSES.ARCHIVED])
  })
  builder.can(ACTIONS.RESPOND, SUBJECTS.PRS, {
    ...isInStatuses([PRS_STATUSES.IN_REVIEW, PRS_STATUSES.REVENUE_ASSESSMENT]),
    ...isInTrialStatuses([PRS_STATUSES.TRIAL_PRE_APPROVAL])
  })
}
const defineVCAAbilities = (builder: PRSItemAbilityBuilder) => {
  builder.can(
    ACTIONS.REASSIGN,
    SUBJECTS.OWNER,
    isInStatuses([
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.SURVEY_CREATION,
      PRS_STATUSES.WAITING_FOR_REVIEW,
      PRS_STATUSES.RCA_REVIEW,
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.CREATED,
      PRS_STATUSES.DECISION_MAKING,
      PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
      PRS_STATUSES.DECLINED,
      PRS_STATUSES.REVENUE_ASSESSMENT
    ])
  )
  builder.can(ACTIONS.VIEW, SUBJECTS.RESULTS_TAB, {
    ...isInStatuses([PRS_STATUSES.ARCHIVED])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.TRIAL_REQUESTED_NOTE, {
    ...isInStatuses([PRS_STATUSES.IN_REVIEW, PRS_STATUSES.REVENUE_ASSESSMENT]),
    ...isInTrialStatuses([PRS_STATUSES.TRIAL_REQUESTED])
  })
}
const defineVCSAbilities = (builder: PRSItemAbilityBuilder) => {
  builder.can(
    ACTIONS.SEND,
    SUBJECTS.PRS,
    isInStatuses([PRS_STATUSES.CREATED, PRS_STATUSES.DECLINED])
  )
  builder.can(
    ACTIONS.CANCEL,
    SUBJECTS.PRS,
    isInStatuses([
      PRS_STATUSES.CREATED,
      PRS_STATUSES.WAITING_FOR_REVIEW,
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.RCA_REVIEW,
      PRS_STATUSES.SURVEY_CREATION,
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.DECISION_MAKING,
      PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
      PRS_STATUSES.REVENUE_ASSESSMENT,
      PRS_STATUSES.DECLINED
    ])
  )
  builder.can(ACTIONS.VIEW, SUBJECTS.RESULTS_TAB, {
    ...isInStatuses([PRS_STATUSES.ARCHIVED])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.TRIAL_REQUESTED_NOTE, {
    ...isInStatuses([PRS_STATUSES.IN_REVIEW, PRS_STATUSES.REVENUE_ASSESSMENT]),
    ...isInTrialStatuses([PRS_STATUSES.TRIAL_REQUESTED])
  })
  builder.can(
    ACTIONS.CONFIRM,
    SUBJECTS.TRIAL,
    isInTrialStatuses([PRS_STATUSES.TRIAL_REQUESTED])
  )
}

const defineCommunityResponsibleAbilities = (
  builder: PRSItemAbilityBuilder,
  user
) => {
  builder.can(ACTIONS.EDIT, SUBJECTS.HEALTH_SYSTEMS, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.IN_REVIEW])
  })
  builder.can(ACTIONS.SEE, SUBJECTS.LABEL, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL])
  })
  builder.can(ACTIONS.SEE, SUBJECTS.CS_APPROVAL_LABEL, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.IN_REVIEW])
  })
  builder.can(ACTIONS.ASSIGN, SUBJECTS.CLINICAL_SPONSOR, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.WAITING_FOR_REVIEW])
  })

  builder.can(ACTIONS.SEND, SUBJECTS.CLINICAL_SPONSOR, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.WAITING_FOR_REVIEW])
  })
  builder.can(ACTIONS.MANAGE, SUBJECTS.TIMER, {
    ...isCommunityResponsible(user)
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.CLINICAL_SPONSOR, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.WAITING_FOR_REVIEW])
  })
  builder.can(ACTIONS.REASSIGN, SUBJECTS.CLINICAL_SPONSOR, {
    ...isCommunityResponsible(user),
    ...isInStatuses([
      PRS_STATUSES.WAITING_FOR_REVIEW,
      PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL
    ])
  })
  builder.can(ACTIONS.CREATE, SUBJECTS.SURVEY, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.SURVEY_CREATION])
  })
  builder.can(ACTIONS.OPEN, SUBJECTS.SURVEY, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.SURVEY_CREATION])
  })
  builder.can(ACTIONS.DECLINE, SUBJECTS.PRS, {
    ...isCommunityResponsible(user),
    ...isInStatuses([
      PRS_STATUSES.CREATED,
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.RCA_REVIEW,
      PRS_STATUSES.SURVEY_CREATION,
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.DECISION_MAKING,
      PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
      PRS_STATUSES.REVENUE_ASSESSMENT
    ])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.FACILITY_USERS, {
    ...isCommunityResponsible(user),
    ...isInStatuses([
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.SURVEY_CREATION,
      PRS_STATUSES.WAITING_FOR_REVIEW,
      PRS_STATUSES.RCA_REVIEW,
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
      PRS_STATUSES.CANCELED,
      PRS_STATUSES.DECLINED,
      PRS_STATUSES.ARCHIVED,
      PRS_STATUSES.CREATED,
      PRS_STATUSES.DECISION_MAKING,
      PRS_STATUSES.REVENUE_ASSESSMENT
    ])
  })
  builder.can(ACTIONS.EDIT, SUBJECTS.FACILITY_USERS, {
    ...isCommunityResponsible(user),
    ...isInStatuses([
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.SURVEY_CREATION,
      PRS_STATUSES.WAITING_FOR_REVIEW,
      PRS_STATUSES.RCA_REVIEW,
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
      PRS_STATUSES.REVENUE_ASSESSMENT
    ])
  })
  if (user.role === ROLES.VICE_PRESIDENT) {
    builder.can(ACTIONS.VIEW, SUBJECTS.PRE_APPROVAL_TAB, {
      ...isCommunityResponsible(user),
      ...isInStatuses([
        PRS_STATUSES.IN_REVIEW,
        PRS_STATUSES.REVENUE_ASSESSMENT
      ]),
      ...isInTrialStatuses([
        PRS_STATUSES.TRIAL_PRE_APPROVAL,
        PRS_STATUSES.TRIAL_REQUESTED,
        PRS_STATUSES.TRIAL_IN_PROGRESS
      ])
    })
    builder.can(ACTIONS.REASSIGN, SUBJECTS.COMMUNITY_OWNER, {
      ...isInStatuses([
        PRS_STATUSES.IN_REVIEW,
        PRS_STATUSES.SURVEY_CREATION,
        PRS_STATUSES.WAITING_FOR_REVIEW,
        PRS_STATUSES.RCA_REVIEW,
        PRS_STATUSES.VOTING_IN_PROGRESS,
        PRS_STATUSES.CREATED,
        PRS_STATUSES.DECISION_MAKING,
        PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
        PRS_STATUSES.REVENUE_ASSESSMENT
      ])
    })
  }
  builder.can(ACTIONS.SEND_TO_RCA, SUBJECTS.PRS, {
    ...isCommunityResponsible(user),
    ...isInStatuses([
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.TRIAL_PRE_APPROVAL,
      PRS_STATUSES.TRIAL_REQUESTED,
      PRS_STATUSES.TRIAL_IN_PROGRESS
    ])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.VOTING_RESPONSE, {
    ...isCommunityResponsible(user),
    ...isInStatuses([
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.DECISION_MAKING,
      PRS_STATUSES.DECLINED,
      PRS_STATUSES.ARCHIVED
    ])
  })
  builder.can(ACTIONS.EDIT, SUBJECTS.SURVEY, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.SURVEY_CREATION])
  })
  builder.can(ACTIONS.MANAGE, SUBJECTS.SURVEY, {
    ...isCommunityResponsible(user),
    ...isInStatuses([
      PRS_STATUSES.SURVEY_CREATION,
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.DECISION_MAKING,
      PRS_STATUSES.DECLINED,
      PRS_STATUSES.ARCHIVED
    ])
  })
  builder.can(ACTIONS.SEND, SUBJECTS.SURVEY, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.SURVEY_CREATION])
  })
  builder.can(ACTIONS.REQUEST, SUBJECTS.TRIAL, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.IN_REVIEW, PRS_STATUSES.REVENUE_ASSESSMENT])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.CLINICAL_RATIONALE, {
    ...isInStatuses([
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.REVENUE_ASSESSMENT,
      PRS_STATUSES.DECLINED,
      PRS_STATUSES.DECISION_MAKING,
      PRS_STATUSES.CANCELED,
      PRS_STATUSES.SURVEY_CREATION,
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
      PRS_STATUSES.ARCHIVED
    ])
  })
  builder.can(ACTIONS.RUN, SUBJECTS.STEP, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.IN_REVIEW])
  })
  builder.can(ACTIONS.START, SUBJECTS.SURVEY, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.IN_REVIEW]),
    ...RcaStatusUnion([RCA_DECISION.RCA_APPROVED, RCA_DECISION.RCA_DECLINED])
  })
  builder.can(ACTIONS.SEND, SUBJECTS.TO_VENDOR, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.REVENUE_ASSESSMENT]),
    ...isInTrialStatuses([PRS_STATUSES.TRIAL_PRE_APPROVAL])
  })
  builder.can(ACTIONS.SEND, SUBJECTS.TO_VENDOR, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.IN_REVIEW]),
    ...RcaStatusUnion([RCA_DECISION.RCA_APPROVED, RCA_DECISION.RCA_DECLINED]),
    ...isInTrialStatuses([PRS_STATUSES.TRIAL_PRE_APPROVAL])
  })
  builder.can(ACTIONS.REASSIGN, SUBJECTS.RCA, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.REVENUE_ASSESSMENT])
  })
  builder.can(ACTIONS.CREATE, SUBJECTS.CONTRACT, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.DECISION_MAKING])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.RESULTS_TAB, {
    ...isInStatuses([PRS_STATUSES.ARCHIVED])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.RCA_APPROVE_LABEL, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.IN_REVIEW])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.PRE_APPROVAL_TAB, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.IN_REVIEW, PRS_STATUSES.REVENUE_ASSESSMENT]),
    ...isInTrialStatuses([
      PRS_STATUSES.TRIAL_PRE_APPROVAL,
      PRS_STATUSES.TRIAL_REQUESTED,
      PRS_STATUSES.TRIAL_IN_PROGRESS
    ])
  })
  builder.can(ACTIONS.COMPLETE, SUBJECTS.VOTING, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.VOTING_IN_PROGRESS])
  })
  builder.can(ACTIONS.START, SUBJECTS.REVENUE_ASSESSMENT, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.IN_REVIEW]),
    ...isInTrialStatuses([
      PRS_STATUSES.TRIAL_REQUESTED,
      PRS_STATUSES.TRIAL_IN_PROGRESS
    ])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.TRIAL_REQUESTED_NOTE, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.IN_REVIEW, PRS_STATUSES.REVENUE_ASSESSMENT]),
    ...isInTrialStatuses([PRS_STATUSES.TRIAL_REQUESTED])
  })
  builder.can(ACTIONS.COMPLETE, SUBJECTS.TRIAL, {
    ...isCommunityResponsible(user),
    ...isInStatuses([PRS_STATUSES.IN_REVIEW, PRS_STATUSES.REVENUE_ASSESSMENT]),
    ...isInTrialStatuses([
      PRS_STATUSES.TRIAL_PRE_APPROVAL,
      PRS_STATUSES.TRIAL_REQUESTED,
      PRS_STATUSES.TRIAL_IN_PROGRESS
    ])
  })
}
const defineRCAAbilities = (builder: PRSItemAbilityBuilder, user) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.CLINICAL_RATIONALE, {
    ...isInStatuses([
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.REVENUE_ASSESSMENT,
      PRS_STATUSES.DECLINED,
      PRS_STATUSES.DECISION_MAKING,
      PRS_STATUSES.CANCELED,
      PRS_STATUSES.SURVEY_CREATION,
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
      PRS_STATUSES.ARCHIVED
    ])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.FACILITY_USERS, {
    ...isInStatuses([PRS_STATUSES.REVENUE_ASSESSMENT, PRS_STATUSES.IN_REVIEW])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.RESULTS_TAB, {
    ...isInStatuses([PRS_STATUSES.ARCHIVED])
  })
  builder.can(ACTIONS.RCA, SUBJECTS.APPROVE, {
    ...isRcaResponsible(user),
    ...isInStatuses([PRS_STATUSES.REVENUE_ASSESSMENT])
  })
  builder.can(ACTIONS.RCA, SUBJECTS.DECLINE, {
    ...isRcaResponsible(user),
    ...isInStatuses([PRS_STATUSES.REVENUE_ASSESSMENT])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.RCA_APPROVE_LABEL, {
    ...isRcaResponsible(user),
    ...isInStatuses([PRS_STATUSES.IN_REVIEW])
  })
}

const defineCPAbilities = (builder: PRSItemAbilityBuilder) => {
  builder.can(ACTIONS.REASSIGN, SUBJECTS.COMMUNITY_OWNER, {
    ...isInStatuses([
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.SURVEY_CREATION,
      PRS_STATUSES.WAITING_FOR_REVIEW,
      PRS_STATUSES.RCA_REVIEW,
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.CREATED,
      PRS_STATUSES.DECISION_MAKING,
      PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
      PRS_STATUSES.REVENUE_ASSESSMENT
    ])
  })

  builder.can(ACTIONS.VIEW, SUBJECTS.CLINICAL_RATIONALE, {
    ...isInStatuses([
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.REVENUE_ASSESSMENT,
      PRS_STATUSES.DECLINED,
      PRS_STATUSES.DECISION_MAKING,
      PRS_STATUSES.CANCELED,
      PRS_STATUSES.SURVEY_CREATION,
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
      PRS_STATUSES.ARCHIVED
    ])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.RESULTS_TAB, {
    ...isInStatuses([PRS_STATUSES.ARCHIVED])
  })

  builder.can(ACTIONS.VIEW, SUBJECTS.FACILITY_USERS, {
    ...isInStatuses([
      PRS_STATUSES.IN_REVIEW,
      PRS_STATUSES.SURVEY_CREATION,
      PRS_STATUSES.WAITING_FOR_REVIEW,
      PRS_STATUSES.RCA_REVIEW,
      PRS_STATUSES.VOTING_IN_PROGRESS,
      PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
      PRS_STATUSES.CANCELED,
      PRS_STATUSES.DECLINED,
      PRS_STATUSES.ARCHIVED,
      PRS_STATUSES.CREATED,
      PRS_STATUSES.DECISION_MAKING,
      PRS_STATUSES.REVENUE_ASSESSMENT
    ])
  })

  builder.can(ACTIONS.VIEW, SUBJECTS.PRE_APPROVAL_TAB, {
    ...isInStatuses([PRS_STATUSES.IN_REVIEW, PRS_STATUSES.REVENUE_ASSESSMENT]),
    ...isInTrialStatuses([
      PRS_STATUSES.TRIAL_PRE_APPROVAL,
      PRS_STATUSES.TRIAL_REQUESTED,
      PRS_STATUSES.TRIAL_IN_PROGRESS
    ])
  })
}

const defineAnonymousAbilities = (builder: PRSItemAbilityBuilder) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.PRS)
}
