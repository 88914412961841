import { createContext, FC, PropsWithChildren, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createContextualCan, useAbility } from '@casl/react'
import { getUser } from 'redux/store/user/getters'
import { ability, defineAbility, FinancialsAbility } from '../Abilities'

export const FinancialsAbilityContext =
  createContext<FinancialsAbility>(ability)

const FinancialsAbilityProvider: FC<PropsWithChildren> = ({ children }) => {
  const user = useSelector(getUser)

  const ability = useMemo(() => defineAbility(user), [user])

  return (
    <FinancialsAbilityContext.Provider value={ability}>
      {children}
    </FinancialsAbilityContext.Provider>
  )
}

export const useFinancialsAbility = () =>
  useAbility<FinancialsAbility>(FinancialsAbilityContext)

export const Can = createContextualCan<any>(FinancialsAbilityContext.Consumer)

export default FinancialsAbilityProvider
