import { Route, Routes } from 'react-router-dom'
import { contractCategoryDetailsRoutes } from './routes'
import { General } from './General/General'

export const ContractCategoryDetailsRouter = () => (
  <Routes>
    {contractCategoryDetailsRoutes.map((route, index) => (
      <Route key={index} path={route.path} element={<route.component />} />
    ))}
    <Route index element={<General />} />
  </Routes>
)
