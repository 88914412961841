import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  HSAmendmentRequestData,
  IContractHealthSystem,
  THSAmendmentFile,
  TGetHealthSystemsRequest
} from 'features/ContractDetails/HSList/types'
import Fetcher from 'services/Fetcher'
import { ResponseWithPagination } from '../../../constants'

const fetcher = new Fetcher({})

export const getContractHealthSystems: TGetHealthSystemsRequest = async (
  id,
  data
) =>
  fetcher.request<unknown, ResponseWithPagination<IContractHealthSystem>>({
    url: `${API_DICTIONARY.CONTRACTS}${id}/${API_DICTIONARY.HEALTH_SYSTEMS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const uploadHSAmendmentDocumentRequestAsync = async (data: FormData) =>
  fetcher.request<FormData, THSAmendmentFile>({
    url: `${API_DICTIONARY.FILES}${API_DICTIONARY.CONTRACT_AMENDMENT_DOCUMENTS}`,
    method: HTTP_METHODS.POST,
    data
  })

export const addHSAmendmentRequestAsync = async (
  id: string,
  hsId: string,
  data: HSAmendmentRequestData
) =>
  fetcher.request<HSAmendmentRequestData, unknown>({
    url: `${API_DICTIONARY.CONTRACTS}${id}/${API_DICTIONARY.HEALTH_SYSTEMS}${hsId}/`,
    method: HTTP_METHODS.POST,
    data
  })
